import { Event } from "@/api/alarm";
import { Autocomplete } from "@mb-pro-ui/components/form";
import { useGetAll } from "@mb-pro-ui/utils";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  CircularProgress,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import identity from "lodash/identity";
import { useCallback, useMemo } from "react";
import { FormRenderProps, useFormState } from "react-final-form";
import { useIntl } from "react-intl";
import FieldArray from "../form/FieldArray";
import FormLayout from "../form/FormLayout";
import { StyledTextField } from "../form/StyledComponents";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import { FormValues } from "./data";

export const CodeFilterInfo = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  return (
    <BootstrapTooltip
      title={
        <List disablePadding dense>
          <ListItem>
            <ListItemText
              primary={formatMessage({
                defaultMessage: "| (vertical bar)",
              })}
              secondary={formatMessage({
                defaultMessage: "separates alternatives",
              })}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={formatMessage({
                defaultMessage: "_ (underscore)",
              })}
              secondary={formatMessage({
                defaultMessage: "matches any single character",
              })}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={formatMessage({
                defaultMessage: "% (percent sign)",
              })}
              secondary={formatMessage({
                defaultMessage:
                  "matches any sequence of zero or more characters",
              })}
            />
          </ListItem>
        </List>
      }
      backgroundColor={theme.palette.background.paper}
    >
      <InfoIcon fontSize="small" />
    </BootstrapTooltip>
  );
};

export const FilteredEventsInfo = ({
  events,
  isLoading,
}: {
  events?: Event[];
  isLoading: boolean;
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return isLoading ? (
    <CircularProgress size="24px" />
  ) : (
    <InputAdornment position="end">
      <small>
        {formatMessage(
          { defaultMessage: "{count} event" },
          { count: events?.length },
        )}
      </small>
      <BootstrapTooltip
        backgroundColor={theme.palette.background.paper}
        arrow
        sx={{ padding: 0 }}
        placement="right"
        title={
          <List
            disablePadding
            dense
            sx={{ maxHeight: "300px", overflow: "auto" }}
          >
            {events?.map((event) => (
              <ListItem key={event.id}>
                <ListItemText
                  primary={event.code}
                  secondary={event["localized-description"]}
                />
              </ListItem>
            ))}
          </List>
        }
      >
        <span style={{ display: "flex", alignItems: "center" }}>
          {!!events?.length && events.length > 0 && (
            <InfoIcon fontSize="small" color="info" sx={{ marginLeft: 1 }} />
          )}
        </span>
      </BootstrapTooltip>
    </InputAdornment>
  );
};

const EventDuplicate = (
  state: FormRenderProps<any, any> & {
    isCreateMode: boolean;
    codeTableID?: string;
  },
) => {
  const { formatMessage } = useIntl();

  const { data: events, isLoading: areEventsLoading } = useGetAll<Event>(
    "alarm/events",
    {
      filter: {
        "code-table": {
          eq: state.codeTableID,
        },
      },
      enabled: !!state.codeTableID,
    },
  );

  const { data: filteredEvents, isLoading: areFilteredEventsLoading } =
    useGetAll<Event>("alarm/events", {
      filter: {
        "code-table": {
          eq: state.codeTableID,
        },
        code: { similar: state.values["event-code"] },
      },
      enabled: !!state.codeTableID,
    });

  const [eventOptions, eventValues] = useMemo(() => {
    const options = [] as string[];
    const values: { [key: string]: Event } = {};
    events?.forEach((c) => {
      options.push(c.code);
      values[c.code] = c;
    });
    return [options, values];
  }, [events]);

  return (
    <Box display="flex" flexDirection="column" minWidth="450px">
      <Typography variant="subtitle1">
        {formatMessage({
          defaultMessage: "Start event filter",
        })}
      </Typography>
      <StyledTextField
        name="event-code"
        label={formatMessage(
          {
            defaultMessage: "Event code filter {icon}",
          },
          {
            icon: <CodeFilterInfo />,
          },
        )}
        parse={identity}
        required
        InputProps={{
          endAdornment: (
            <FilteredEventsInfo
              events={filteredEvents}
              isLoading={areFilteredEventsLoading}
            />
          ),
        }}
        highlightDirty={!state.isCreateMode}
      />
      <StyledTextField
        name="zone"
        label={formatMessage({
          defaultMessage: "Zone filter",
        })}
        parse={identity}
        highlightDirty={!state.isCreateMode}
      />
      <StyledTextField
        name="partition"
        label={formatMessage({
          defaultMessage: "Partition filter",
        })}
        parse={identity}
        highlightDirty={!state.isCreateMode}
      />
      <Typography variant="subtitle1">
        {formatMessage({
          defaultMessage: "Duplicated event",
        })}
      </Typography>
      <Autocomplete
        name="new-event-code"
        options={eventOptions}
        label={formatMessage({ defaultMessage: "Duplicated event code" })}
        highlightDirty={!state.isCreateMode}
        loading={areEventsLoading}
        fullWidth
        sx={{ px: 1 }}
        stringify={(option) =>
          `${option}\n${eventValues[option]?.["localized-description"]}`
        }
      />
    </Box>
  );
};

interface EventDuplicateFormProps {
  readOnly?: boolean;
}

const EventDuplicateForm = ({ readOnly }: EventDuplicateFormProps) => {
  const { formatMessage } = useIntl();

  const labels = useMemo(
    () => ({
      "event-code": formatMessage({
        defaultMessage: "Event code filter",
      }),
      zone: formatMessage({
        defaultMessage: "Zone filter",
      }),
      partition: formatMessage({
        defaultMessage: "Partition filter",
      }),
      "new-event-code": formatMessage({
        defaultMessage: "New event code",
      }),
    }),
    [formatMessage],
  );

  const getField = useCallback((item: any, key: string, defaultValue?: any) => {
    if (key === "zone" || key === "partition") {
      return String((item[key] || defaultValue) ?? "");
    }
    return String(item[key] ?? defaultValue ?? "");
  }, []);

  const {
    values: { "code-table": codeTable },
  } = useFormState<FormValues>();

  return (
    <FormLayout
      firstChild={
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr" }}>
          <FieldArray
            name="event-duplicates"
            label={formatMessage({
              defaultMessage: "Event duplication",
            })}
            formDialogHeaderNew={formatMessage({
              defaultMessage: "New event duplication",
            })}
            formDialogHeaderEdit={formatMessage({
              defaultMessage: "Edit event duplication",
            })}
            labels={labels}
            formDialogRenderer={(props) => (
              <EventDuplicate {...props} codeTableID={codeTable?.id} />
            )}
            readOnly={readOnly}
            getField={getField}
          />
        </Box>
      }
    />
  );
};

export default EventDuplicateForm;

import { Customer as _Customer } from "@/api/alarm";
import { WithFields, WithIncludes } from "@/api/jsonapi";
import { TextField } from "@mb-pro-ui/components";
import useDebounce from "@mb-pro-ui/components/utils/useDebounce";
import { useGetAll } from "@mb-pro-ui/utils";
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  Children,
  Fragment,
  HTMLAttributes,
  ReactNode,
  forwardRef,
  memo,
  useMemo,
  useState,
} from "react";
import { useIntl } from "react-intl";

const fields = {
  customers: ["account", "name", "address", "active", "unitids"],
} as const;

const include = { unitids: {} } as const;

export type Customer = WithIncludes<
  WithFields<_Customer, typeof fields>,
  typeof include
>;

function formatUnitids(unitids: Customer["unitids"]) {
  return (
    unitids
      ?.slice(0, 2)
      .map((u) => u.unitid)
      ?.join(", ") ?? ""
  );
}

function formatCustomer(customer?: Customer) {
  return customer
    ? [customer.name, customer.address].filter(Boolean).join(", ")
    : "";
}

const HighlightedText = memo(
  ({ text, query }: { text: string; query: string }) => (
    <>
      {parse(
        text,
        match(text, query, { findAllOccurrences: true, insideWords: true }),
      ).map(({ text, highlight }, index) =>
        highlight ? (
          <b key={index}>{text}</b>
        ) : (
          <Fragment key={index}>{text}</Fragment>
        ),
      )}
    </>
  ),
);

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  customer: Customer,
  { inputValue, selected }: AutocompleteRenderOptionState,
) => {
  const lines = [
    `${customer.account} ${customer.name ?? ""}`,
    formatUnitids(customer.unitids),
    customer.address ?? "",
  ];

  return (
    <li {...props}>
      <div>
        {lines.map((line, i) => {
          if (!line) {
            return null;
          }

          const text = selected ? (
            line
          ) : (
            <HighlightedText text={line} query={inputValue} />
          );

          return i === 0 ? (
            <div key={0}>{text}</div>
          ) : (
            <div key={i} style={{ color: "#2587BC", fontSize: "14px" }}>
              {text}
            </div>
          );
        })}
      </div>
    </li>
  );
};

const limit = 10;

const ListboxComponent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLElement>
>(({ children, ...other }, ref) => {
  const itemData = Children.toArray(children);
  const itemCount = itemData.length;
  const { formatMessage } = useIntl();
  return (
    <div ref={ref}>
      <ul {...other}>{itemData}</ul>
      {itemCount === limit && (
        <div style={{ padding: "8px", textAlign: "center", fontSize: "14px" }}>
          {formatMessage(
            { defaultMessage: "Showing first {limit, number} results" },
            { limit },
          )}
        </div>
      )}
    </div>
  );
});

const CustomerSelect = ({
  value,
  setValue,
  autoFocus,
  label,
}: {
  value: Customer | undefined;
  setValue: (value: Customer) => void;
  autoFocus?: boolean;
  label?: ReactNode;
}) => {
  const { formatMessage } = useIntl();
  const [inputValue_, setInputValue] = useState("");

  const debouncedInputValue = useDebounce(inputValue_, 300);

  const pattern = `%${debouncedInputValue.replace(/([%_\\])/g, "\\$1")}%`;

  const { data: exact, isLoading: isLoadingExact } = useGetAll<Customer>(
    "alarm/customers",
    {
      fields,
      include,
      filter: {
        account: { pattern: debouncedInputValue.replace(/([%_\\])/g, "\\$1") },
      },
      sort: ["account"],
      page: { limit: 1 },
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      enabled: debouncedInputValue.length >= 4,
    },
  );

  const { data, isLoading } = useGetAll<Customer>("alarm/customers", {
    fields,
    include,
    filter: {
      or: {
        account: { pattern },
        name: { pattern },
        address: { pattern },
        unitid: { pattern },
        id: { eq: value?.id },
      },
    },
    page: { limit },
    sort: ["account"],
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: debouncedInputValue.length >= 2,
  });

  const options = useMemo(() => {
    if (!data) {
      return exact ?? [];
    }

    if (exact?.length === 1) {
      const accIdx = data.findIndex((c) => c.id === exact[0].id);
      if (accIdx !== -1) {
        return [exact[0], ...data.filter((_, i) => i !== accIdx)];
      }
      return [exact[0], ...data];
    }

    const lowerInput = debouncedInputValue.toLocaleLowerCase();
    const accIdx = data.findIndex(
      (c) => c.account.toLocaleLowerCase() === lowerInput,
    );
    if (accIdx !== -1) {
      return [data[accIdx], ...data.filter((_, i) => i !== accIdx)];
    }
    return data;
  }, [data, exact, debouncedInputValue]);

  return (
    <Autocomplete<Customer, false, true, false>
      getOptionLabel={(customer) => customer?.account ?? ""}
      isOptionEqualToValue={(option, value) =>
        option === value || option.id === value.id
      }
      options={options}
      filterOptions={(x) => x}
      value={value}
      noOptionsText={
        <Typography variant="body2" color="textSecondary">
          {isLoading || isLoadingExact
            ? formatMessage({ defaultMessage: "Loading..." })
            : debouncedInputValue.length < 2
              ? formatMessage({
                  defaultMessage:
                    "Search for a customer by account, name, address or Helios ID",
                })
              : formatMessage({ defaultMessage: "No options" })}
        </Typography>
      }
      onChange={(_, newValue) => setValue(newValue)}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      disableClearable
      autoComplete
      autoSelect
      autoHighlight
      renderInput={(params) => (
        <TextField
          {...params}
          className={value ? "Mbp-dirty" : ""}
          label={label}
          fullWidth
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          required
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <InputAdornment
                    position="end"
                    sx={{ bottom: 8, position: "relative" }}
                  >
                    <CircularProgress color="inherit" size={20} />
                  </InputAdornment>
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          helperText={value ? formatCustomer(value) : "\u00A0"}
        />
      )}
      renderOption={renderOption}
      ListboxComponent={ListboxComponent}
    />
  );
};

export default CustomerSelect;

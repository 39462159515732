import { EmptyPlaceholder, PlaceholderIcon } from "@mb-pro-ui/components";
import {
  Box,
  CircularProgress,
  Paper,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { ForwardedRef, ReactNode, forwardRef } from "react";

interface WidgetProps {
  title?: ReactNode;
  postfix?: ReactNode;
  children?: ReactNode;
  className?: string;
  placeholder?: boolean;
  placeholderMessage?: string;
  disabled?: boolean;
  placeholderIcon?: PlaceholderIcon;
  isLoading?: boolean;
  sx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
  noShadow?: boolean;
  backgroundColor?: string;
}

const Widget = forwardRef(
  (
    {
      children,
      className,
      title,
      postfix,
      placeholder,
      disabled,
      placeholderMessage,
      placeholderIcon,
      isLoading,
      sx,
      headerSx,
      noShadow,
      backgroundColor,
    }: WidgetProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <Paper
      className={className}
      elevation={noShadow ? 0 : 1}
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          background: backgroundColor ?? backgroundColor,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      ref={ref}
    >
      {title && (
        <Box
          sx={[
            {
              backgroundColor: (theme) =>
                disabled ? theme.palette.grey[400] : theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              px: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              minHeight: "50px",
            },
            ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
          ]}
          paddingY={1}
        >
          <Typography noWrap>{title}</Typography>
          {postfix && postfix}
        </Box>
      )}
      {isLoading ? (
        <Box
          sx={{
            height: (theme) =>
              `calc(100% - ${
                theme.typography.subtitle2.fontSize
              } - ${theme.spacing(3)})`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <CircularProgress />
        </Box>
      ) : !children && (placeholder || placeholderMessage) ? (
        <EmptyPlaceholder
          message={placeholderMessage}
          Icon={placeholderIcon}
          sx={{
            height: (theme) =>
              `calc(100% - ${
                theme.typography.subtitle2.fontSize
              } - ${theme.spacing(3)})`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            width: "100%",
          }}
        />
      ) : (
        children
      )}
    </Paper>
  ),
);

export default Widget;

import { defineMessages, MessageDescriptor } from "react-intl";

export default defineMessages({
  "errors.account-registered": {
    defaultMessage:
      "The account is already registered. Choose another, e.g. {account}.",
    id: "errors.account-registered",
  },
  "errors.ack-event-pattern-no-match": {
    defaultMessage:
      "The customer's code table has no event matching the {value} rule.",
    id: "errors.ack-event-pattern-no-match",
  },
  "errors.ack-partition-pattern-no-match": {
    defaultMessage: "Invalid partition number rule: {value}",
    id: "errors.ack-partition-pattern-no-match",
  },
  "errors.ack-zone-pattern-no-match": {
    defaultMessage: "Invalid zone number rule: {value}",
    id: "errors.ack-zone-pattern-no-match",
  },
  "errors.amigous-auth-header-and-mbh": {
    defaultMessage: "Ambigous authentication data.",
    id: "errors.amigous-auth-header-and-mbh",
  },
  "errors.amigous-auth-header-and-sid": {
    defaultMessage: "Ambigous authentication data.",
    id: "errors.amigous-auth-header-and-sid",
  },
  "errors.amigous-auth-sid-and-mbh": {
    defaultMessage: "Ambigous authentication data.",
    id: "errors.amigous-auth-sid-and-mbh",
  },
  "errors.api-key-not-unique": {
    defaultMessage: "This API key has already been registered.",
    id: "errors.api-key-not-unique",
  },
  "errors.description-not-unique": {
    defaultMessage: "The description is not unique. Choose another.",
    id: "errors.description-not-unique",
  },
  "errors.empty-operator-login-name": {
    defaultMessage: "Cannot create operator with empty username.",
    id: "errors.empty-operator-login-name",
  },
  "errors.existing-user-invalid-password": {
    defaultMessage:
      "The given password doesn't match the current password of the user.",
    id: "errors.existing-user-invalid-password",
  },
  "errors.insufficient-permissions": {
    defaultMessage:
      "The logged in user has insufficient permissions for this operation.",
    id: "errors.insufficient-permissions",
  },
  "errors.internal-error": {
    defaultMessage:
      "Unsuccessful operation. Verify the parameters. If unable to resolve the error, contact us at support@mohanet.com.",
    id: "errors.internal-error",
  },
  "errors.invalid-2-hex-number": {
    defaultMessage:
      "Invalid value: {value}. The '{field}' field must be a 2 digit hexadecimal upper case number.",
    id: "errors.invalid-2-hex-number",
  },
  "errors.invalid-3-hex-number": {
    defaultMessage:
      "Invalid value: {value}. The '{field}' field must be a 3 digit hexadecimal upper case number.",
    id: "errors.invalid-3-hex-number",
  },
  "errors.invalid-access-token": {
    defaultMessage: "Invalid access token.",
    id: "errors.invalid-access-token",
  },
  "errors.invalid-ack-event-rule": {
    defaultMessage:
      "Invalid wait-for-effect type: {value}. Valid values: 'none', 'event', 'category', 'statealarm'",
    id: "errors.invalid-ack-event-rule",
  },
  "errors.invalid-button-index": {
    defaultMessage: "Invalid remote control button index: {value}",
    id: "errors.invalid-button-index",
  },
  "errors.invalid-event-code-list": {
    defaultMessage: "Invalid event code list.",
    id: "errors.invalid-event-code-list",
  },
  "errors.invalid-integer-id": {
    defaultMessage:
      "Invalid value: {value}. The '{field}' field should be a positive integer.",
    id: "errors.invalid-integer-id",
  },
  "errors.invalid-mbhome-authorization-data": {
    defaultMessage: "Invalid Monitoringbook Home authentication data",
    id: "errors.invalid-mbhome-authorization-data",
  },
  "errors.invalid-operator-login-name": {
    defaultMessage: "Cannot create operator with {login} username.",
    id: "errors.invalid-operator-login-name",
  },
  "errors.invalid-operator-password": {
    defaultMessage:
      "Cannot create operator with the given password. Minimum password length: 6 characters.",
    id: "errors.invalid-operator-password",
  },
  "errors.invalid-username-or-password": {
    defaultMessage: "Invalid username or password",
    id: "errors.invalid-username-or-password",
  },
  "errors.invalid-uuid-id": {
    defaultMessage:
      "Invalid value: {value}. The '{field}' field must be an UUID formatted identifier.",
    id: "errors.invalid-uuid-id",
  },
  "errors.missing-authorization": {
    defaultMessage: "Request not authenticated.",
    id: "errors.missing-authorization",
  },
  "errors.required-field-missing": {
    defaultMessage: "Required field '{field}' is missing",
    id: "errors.required-field-missing",
  },
  "errors.rfid-number-in-this-customer": {
    defaultMessage:
      "An other RFID token has already been registered with {number} number to this customer.",
    id: "errors.rfid-number-in-this-customer",
  },
  "errors.rfid-token-in-other-customer": {
    defaultMessage:
      "The {code} RFID token has already been registered to an other custmer.",
    id: "errors.rfid-token-in-other-customer",
  },
  "errors.rfid-token-in-this-customer": {
    defaultMessage:
      "The {code} RFID token has already been registered to this customer.",
    id: "errors.rfid-token-in-this-customer",
  },
  "errors.rfid-token-too-long-code": {
    defaultMessage: "The maximum length for RFID is 8 digits.",
    id: "errors.rfid-token-too-long-code",
  },
  "errors.unexpected-field": {
    defaultMessage: "Unexpected field: '{field}'",
    id: "errors.unexpected-field",
  },
  "fields.customer": {
    defaultMessage: "customer",
    id: "fields.customer",
  },
  "fields.number": {
    defaultMessage: "number",
    id: "fields.number",
  },
  "fields.temp-inactive-ts": {
    defaultMessage: "temporary bypass",
    id: "fields.temp-inactive-ts",
  },
  "errors.required-parameter-missing": {
    defaultMessage: "Required parameter {param} is missing.",
    id: "errors.required-parameter-missing",
  },
  "errors.invalid-parameter": {
    defaultMessage: "Invalid parameter: {param}.",
    id: "errors.invalid-parameter",
  },
  "errors.invalid-parameter-value": {
    defaultMessage: "Invalid value {value} for parameter {param}.",
    id: "errors.invalid-parameter-value",
  },
  "errors.invalid-billing-classification": {
    defaultMessage: "Invalid billing classification",
    id: "errors.invalid-billing-classification",
  },
  email: {
    defaultMessage: "Email",
    id: "email",
  },
  "errors.event-code-disabled": {
    defaultMessage: "The event code is already disabled",
    id: "errors.event-code-disabled",
  },
  "errors.insufficient-helios-permission": {
    defaultMessage: "Insufficient helios permission",
    id: "errors.insufficient-helios-permission",
  },
  "errors.invalid-rfid-list": {
    defaultMessage: "Invalid Rfid list",
    id: "errors.invalid-rfid-list",
  },
  "errors.helios-not-logged-in": {
    defaultMessage: "Not logged into helios",
    id: "errors.helios-not-logged-in",
  },
  "errors.rfid-download-fail": {
    defaultMessage: "Device did not acknowledge the RFID list",
    id: "errors.rfid-download-fail",
  },
  "errors.account-already-exists": {
    defaultMessage: "Account already exists",
    id: "errors.account-already-exists",
  },
  "errors.smtp-verify-failed": {
    defaultMessage: "SMTP connection error: {message}",
    id: "errors.smtp-verify-failed",
  },
  "errors.failed-to-send-test-email": {
    defaultMessage: "Failed to send test email: {message}",
    id: "errors.failed-to-send-test-email",
  },
  "errors.invalid-field-value": {
    defaultMessage: "Invalid value for field: '{field}'",
    id: "errors.invalid-field-value",
  },
}) as Record<string, MessageDescriptor>;

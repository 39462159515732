import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { intToColor } from "./colors";
import { AnyIcon, Icon } from "./icons";

export const DialogBackButton = ({ onClick }: { onClick: () => void }) => (
  <IconButton
    aria-label="back"
    sx={(theme) => ({
      position: "absolute",
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.common.white,
      "&:hover": { backgroundColor: "unset" },
    })}
    onClick={onClick}
    disableRipple
    size="large"
  >
    <ArrowBackIcon />
  </IconButton>
);

export const DialogCloseButton = ({
  onClick,
  sx = {},
}: {
  onClick: () => void;
  sx?: object;
}) => (
  <IconButton
    aria-label="close"
    sx={(theme) => ({
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.common.white,
      "&:hover": { backgroundColor: "unset" },
      ...sx,
    })}
    onClick={onClick}
    disableRipple
    size="large"
  >
    <CloseIcon />
  </IconButton>
);

export const SyledDialogTitle = ({
  children,
  sx = [],
}: {
  children: ReactNode;
  sx?: SxProps<Theme>;
}) => (
  <DialogTitle
    sx={[
      {
        padding: (theme) => theme.spacing(2),
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <span
      style={{
        display: "inline-block",
        maxWidth: "70%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </span>
  </DialogTitle>
);

const MapMarkerDialog = <
  T extends {
    id: string;
    icon: Icon | null;
    color: number;
    primaryColor?: string;
    alarm?: boolean;
  },
  U extends { id: string },
>({
  open,
  items,
  title,
  todayUnits,
  yesterdeyUnits,
  onClose,
  getTitle,
  renderSelected,
  renderActions,
  getPrimary,
  getSecondary,
  setToday,
  setYesterday,
}: {
  open: boolean;
  items: T[];
  yesterdeyUnits?: U[];
  todayUnits?: U[];
  title?: string;
  setToday?: (id?: string) => void;
  setYesterday?: (id?: string) => void;
  onClose: () => void;
  getTitle: (item: T | null, items: T[]) => string;
  renderSelected: (item: T) => ReactNode;
  renderActions?: (item: T | null, items: T[]) => ReactNode;
  getPrimary?: (item: T) => string | undefined;
  getSecondary?: (item: T) => string | undefined;
}) => {
  const { formatMessage } = useIntl();

  const [selected, select] = useState<T | null>(null);
  useEffect(() => select(items.length === 1 ? items[0] : null), [items]);

  return (
    <Dialog open={open} onClose={onClose}>
      <SyledDialogTitle
        sx={
          selected?.primaryColor
            ? { bgcolor: selected?.primaryColor }
            : undefined
        }
      >
        {title ?? getTitle(selected, items)}
      </SyledDialogTitle>
      {selected && items.length > 1 && (
        <DialogBackButton onClick={() => select(null)} />
      )}
      <DialogCloseButton onClick={onClose} />
      <DialogContent
        sx={[{ minWidth: "600px" }, !selected && { minWidth: "450px", px: 0 }]}
      >
        {selected ? (
          renderSelected(selected)
        ) : (
          <List>
            {items.map((obj) => {
              const todayIsActive: boolean = !!todayUnits?.some(
                (unit) => unit.id === obj.id,
              );
              const yesterdayIsActive: boolean = !!yesterdeyUnits?.some(
                (unit) => unit.id === obj.id,
              );
              return (
                <ListItemButton
                  key={obj.id}
                  onClick={() => select(obj)}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                        marginRight: "10px",
                        alignItems: "center",
                      }}
                    >
                      {obj.icon && (
                        <AnyIcon
                          key={obj.id}
                          icon={obj.icon}
                          alarm={obj.alarm}
                          color={intToColor(obj.color)}
                          sx={{ width: "40px", height: "40px" }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          color={obj.primaryColor}
                          variant="body1"
                          component="span"
                          display="block"
                        >
                          {getPrimary?.(obj)}
                        </Typography>
                      }
                      secondary={getSecondary?.(obj)}
                    />
                  </Box>
                  {(todayUnits || yesterdeyUnits) && (
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                    >
                      <ToggleButtonGroup
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <Tooltip
                          title={formatMessage({
                            defaultMessage: "Yesterday's route",
                          })}
                          slotProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: (theme) =>
                                  theme.palette.info.main,
                              },
                            },
                          }}
                        >
                          <span>
                            <ToggleButton
                              value={obj.id + "yesterday"}
                              selected={yesterdeyUnits?.some(
                                (unit) => unit.id === obj.id,
                              )}
                              onClick={() =>
                                setYesterday ? setYesterday(obj.id) : undefined
                              }
                              sx={{
                                borderRadius: "50% 0 0 50%",
                                padding: 0.5,
                                margin: 0,
                              }}
                            >
                              {yesterdayIsActive ? (
                                <FiberManualRecordIcon
                                  color="info"
                                  fontSize="large"
                                />
                              ) : (
                                <TripOriginIcon
                                  color="disabled"
                                  fontSize="large"
                                  sx={{
                                    paddingLeft: "2px",
                                    paddingRight: "2px",
                                  }}
                                />
                              )}
                            </ToggleButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={formatMessage({
                            defaultMessage: "Today's route",
                          })}
                        >
                          <span>
                            <ToggleButton
                              value={obj.id + "today"}
                              onClick={() =>
                                setToday ? setToday(obj.id) : undefined
                              }
                              selected={todayUnits?.some(
                                (unit) => unit.id === obj.id,
                              )}
                              sx={{
                                borderRadius: "0 50% 50% 0",
                                padding: 0.5,
                                margin: 0,
                              }}
                            >
                              {todayIsActive ? (
                                <FiberManualRecordIcon
                                  color="primary"
                                  fontSize="large"
                                />
                              ) : (
                                <TripOriginIcon
                                  color="disabled"
                                  fontSize="large"
                                  sx={{
                                    paddingLeft: "2px",
                                    paddingRight: "2px",
                                  }}
                                />
                              )}
                            </ToggleButton>
                          </span>
                        </Tooltip>
                      </ToggleButtonGroup>
                    </Box>
                  )}
                </ListItemButton>
              );
            })}
          </List>
        )}
      </DialogContent>
      {renderActions?.(selected, items)}
    </Dialog>
  );
};

export default MapMarkerDialog;

import { TextField } from "@mb-pro-ui/components";
import { MbProFilterButton } from "@mb-pro-ui/components/table/components";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { ReactNode, useState } from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";

interface DatePickerFuturePresetsProps<K extends string> {
  name: K;
  label: ReactNode;
  readOnly?: boolean;
  highlightDirty?: boolean;
  required?: boolean;
  disabled?: boolean;
}

const useInactiveIntervals = () => {
  const { formatMessage } = useIntl();

  return [
    {
      label: formatMessage({
        defaultMessage: "Next 30 minutes",
        description: "Tamporary inactivity interval",
      }),
      interval: { m: 30 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 1 hour",
        description: "Tamporary inactivity interval",
      }),
      interval: { h: 1 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 2 hours",
        description: "Tamporary inactivity interval",
      }),
      interval: { h: 2 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 4 hours",
        description: "Tamporary inactivity interval",
      }),
      interval: { h: 4 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 8 hours",
        description: "Tamporary inactivity interval",
      }),
      interval: { h: 8 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 12 hours",
        description: "Tamporary inactivity interval",
      }),
      interval: { h: 12 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 1 day",
        description: "Tamporary inactivity interval",
      }),
      interval: { d: 1 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 2 days",
        description: "Tamporary inactivity interval",
      }),
      interval: { d: 2 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 4 days",
        description: "Tamporary inactivity interval",
      }),
      interval: { d: 4 },
    },
    {
      label: formatMessage({
        defaultMessage: "Next 1 week",
        description: "Tamporary inactivity interval",
      }),
      interval: { w: 1 },
    },
  ];
};

export const isMomentEqual = (
  a?: Moment | string | null,
  b?: Moment | string | null,
) => a === b || (!a && !b) || moment(a).isSame(moment(b));

export interface DatePickerFuturePresetsInputProps {
  label: ReactNode;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  value: Moment | null;
  onChange: (value: Moment | null) => void;
  error?: string;
  helperText?: string;
  pickerSx?: SxProps<Theme>;
}

export const DatePickerFuturePresetsInput = ({
  label,
  readOnly,
  required,
  disabled,
  value,
  onChange,
  error,
  helperText,
  pickerSx,
}: DatePickerFuturePresetsInputProps) => {
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIntervalIndex, setSelectedIntervalIndex] = useState<number>();
  const intervals = useInactiveIntervals();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "calc(100%)",
      }}
    >
      <DesktopDateTimePicker
        disablePast
        label={label}
        readOnly={readOnly}
        slots={{ textField: TextField }}
        slotProps={{
          field: { clearable: !required },
          textField: {
            error: !!error,
            required,
            helperText: error || helperText,
          },
        }}
        sx={[{ flex: 1 }, ...(Array.isArray(pickerSx) ? pickerSx : [pickerSx])]}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <MbProFilterButton
        endIcon={<MoreHorizIcon />}
        sx={{
          minWidth: "24px",
          paddingRight: "5px",
          paddingLeft: "5px",
          justifyContent: "center",
          margin: "0 8px 0 0",
        }}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        active={!!anchorEl}
        disabled={readOnly || disabled}
      />
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => onChange(null)}
          selected={selectedIntervalIndex !== 0 && !selectedIntervalIndex}
        >
          {formatMessage({
            defaultMessage: "Unset",
            description: "Datepicker preset unset message",
          })}
        </MenuItem>
        {intervals.map(({ label, interval }, index) => (
          <MenuItem
            key={label}
            selected={index === selectedIntervalIndex}
            onClick={() => {
              onChange(moment().add(interval));
              setSelectedIntervalIndex(index);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const DatePickerFuturePresets = <K extends string>({
  name,
  label,
  readOnly,
  required,
  highlightDirty = true,
  disabled,
}: DatePickerFuturePresetsProps<K>) => (
  <Field<string | null, HTMLElement, Moment | null>
    name={name}
    allowNull
    format={(value) => (value ? moment(value) : null)}
    parse={(value) => (value ? value.toISOString() : null)}
    isEqual={isMomentEqual}
    subscription={{ value: true, dirty: true, error: true }}
  >
    {({ input: { onChange, ...input }, meta: { dirty, error } }) => (
      <DatePickerFuturePresetsInput
        label={label}
        readOnly={readOnly}
        required={required}
        disabled={disabled}
        value={input.value}
        onChange={onChange}
        error={error}
        helperText={error}
        pickerSx={[
          !!dirty &&
            highlightDirty && {
              backgroundColor: (theme) => theme.palette.highlight.dirty,
            },
        ]}
      />
    )}
  </Field>
);

export default DatePickerFuturePresets;

import { VideoCamera } from "@/api/alarm/video-camera";
import { VideoServer } from "@/api/alarm/video-servers";
import { get } from "lodash";
import { Cdec } from "../../cdec/useCdec";
import { ExtendedVideoCamera } from "../CameraModal";

export const processCameras = (
  cameras: VideoCamera[],
  videoServerMap: Map<string, VideoServer>,
  zoneNumber: string,
  include: boolean,
  cdec: Cdec,
) => {
  return cameras
    .filter((camera) => {
      const serverId =
        typeof camera.server === "string" ? camera.server : camera.server.id;
      const videoServer = videoServerMap.get(serverId);

      const isCustomerCamera = videoServer?.customer === cdec.customer?.id;

      return (
        isCustomerCamera &&
        (include
          ? camera.zones?.includes(zoneNumber) ?? false
          : !(camera.zones?.includes(zoneNumber) ?? false))
      );
    })
    .map((camera) => {
      const serverId =
        typeof camera.server === "string" ? camera.server : camera.server.id;
      const videoServer = videoServerMap.get(serverId);
      return { ...camera, videoServer } as ExtendedVideoCamera;
    });
};

export const getCameraPropertyValues = (
  selectedCamera: number | undefined,
  selectedCameraName: string | undefined,
  selector: string,
  customerCameras: {
    withinTheZone: ExtendedVideoCamera[];
    outOfZone: ExtendedVideoCamera[];
  },
) => {
  if (
    selectedCamera === undefined ||
    (customerCameras.withinTheZone.length === 0 &&
      customerCameras.outOfZone.length === 0)
  ) {
    return undefined;
  }

  const allCameras = [
    ...customerCameras.withinTheZone,
    ...customerCameras.outOfZone,
  ];
  const camera = allCameras.find(
    (camera) =>
      camera.config.cameraId === selectedCamera &&
      camera.name === selectedCameraName,
  );

  if (!camera) return undefined;

  return get(camera, selector);
};

import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import SvgIcon from "@mui/material/SvgIcon";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as CustomerManagerMenuIcon } from "../../icons/CustomerManagerMenu.svg";
import { ReactComponent as MapMenuMenuIcon } from "../../icons/MapMenu.svg";
import { ReactComponent as SystemSettingsMenuIcon } from "../../icons/system_settings/SystemSettings.svg";
import { VehicleMonitoringRoutes } from "../types";

const useVehicleMonitoringMenuLinks = () => {
  const { formatMessage } = useIntl();
  return useMemo(
    () => [
      {
        icon: <SvgIcon component={TrackChangesIcon} />,
        href: VehicleMonitoringRoutes.Overview,
        label: formatMessage({ defaultMessage: "Overview" }),
      },
      {
        icon: (
          <SvgIcon component={CustomerManagerMenuIcon} viewBox="0 0 50 50" />
        ),
        href: VehicleMonitoringRoutes.Waybills,
        label: formatMessage({ defaultMessage: "Waybills" }),
      },
      {
        icon: <SvgIcon component={MapMenuMenuIcon} viewBox="0 0 50 50" />,
        href: VehicleMonitoringRoutes.PositionData,
        label: formatMessage({ defaultMessage: "Position data" }),
      },
      {
        icon: <SvgIcon component={SystemSettingsMenuIcon} />,
        href: VehicleMonitoringRoutes.SystemSettings,
        label: formatMessage({
          defaultMessage: "Settings",
          description: "Main menu System Settings label",
        }),
      },
    ],
    [formatMessage],
  );
};

export default useVehicleMonitoringMenuLinks;

import { ID } from "@/api";
import { useCreate, useStorageState } from "@mb-pro-ui/utils";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import DoneIcon from "@mui/icons-material/Done";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from "@mui/icons-material/Send";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FabProps,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  SpeedDial,
  SpeedDialAction,
  SpeedDialActionProps,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  darken,
  listItemTextClasses,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Box } from "@mui/system";
import { LatLng } from "leaflet";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { getErrorMessage } from "../../../hooks/useErrorHandler";
import { ReactComponent as AlertReviewTasksIcon } from "../../../icons/customer/AlertReviewTasks.svg";
import useTimestampFormat from "../../../locales/useTimestampFormat";
import CustomAlert from "../../form/CustomAlert";
import { ModalViewState } from "../../intervention/EventsWidget";
import { Intervention } from "../../intervention/types";
import { SnackbarState } from "../../settings/types";
import { Snackbar } from "../../settings/utils";
import { serializeDateRange } from "../../table/filters/manual/base/DateRangeFilter";
import CoordsLabel from "../../utils/CoordsLabel";
import CameraModal from "../video/CameraModal";
import InactivateDialog from "./InactivateDialog";
import { Cdec, useCdec } from "./useCdec";

type CdecModalProps = {
  viewState?: ModalViewState;
  intervention?: Intervention;
  isUnacknowledgedEvent?: boolean | undefined;
  cdecID: string;
  onClose(): void;
  disableInactivate?: boolean;
  alertReview?: {
    alertReviewId?: number | undefined;
    isAlertReview?: boolean;
    reviewFieldsVisibility?: boolean;
    handleAlert?: (result: boolean, index: number, reason?: string) => void;
    autocompleteOptions?: string[];
    alertReviewTaskDescription?: string;
  };
};

type MbProFabProps<C extends React.ElementType> = Partial<
  FabProps<C, { component?: C }>
>;

type MbProSpeedDialActionProps = Omit<SpeedDialActionProps, "FabProps"> & {
  tooltipTitle?: SpeedDialActionProps["tooltipTitle"];
};

export const MbProSpeedDialAction = styled(
  <C extends React.ElementType>({
    className,
    ...props
  }: MbProSpeedDialActionProps & MbProFabProps<C> & { className?: string }) => {
    const { TooltipClasses, ...rest } = props;
    return (
      <SpeedDialAction
        {...rest}
        TooltipClasses={{ popper: className, ...TooltipClasses }}
      />
    );
  },
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
  },
}));

const ModalListItemText = ({
  color,
  primaryTypographyProps: { sx: primarySx, ...primaryTypographyRestProps } = {},
  secondaryTypographyProps: {
    sx: secondarySx,
    ...secondaryTypographyRestProps
  } = {},
  ...props
}: ListItemTextProps<"span", "div"> & {
  color?: string;
}) => {
  return (
    <ListItemText
      primaryTypographyProps={{
        component: "span",
        sx: [
          {
            [`&.${listItemTextClasses.root}, &.${listItemTextClasses.primary}`]:
              {
                color: color,
              },
          },
          ...(Array.isArray(primarySx) ? primarySx : [primarySx]),
        ],
        ...primaryTypographyRestProps,
      }}
      secondaryTypographyProps={{
        component: "div",
        sx: [
          { overflowWrap: "anywhere" },
          ...(Array.isArray(secondarySx) ? secondarySx : [secondarySx]),
        ],
        ...secondaryTypographyRestProps,
      }}
      {...props}
    />
  );
};

export const CdecModalContents = ({
  cdec,
  disableInactivate,
  latlng,
  viewType,
}: {
  cdec: Cdec;
  disableInactivate?: boolean;
  latlng?: LatLng;
  viewType?: "standard" | "extended";
}) => {
  const { formatMessage } = useIntl();
  const { formatTimestamp } = useTimestampFormat();
  const color = cdec.color ?? cdec.event?.category.hex;
  const customer = cdec.customer;
  const customerID = typeof customer === "string" ? customer : customer?.id;

  const getEventCategory = (cdec: Cdec): string => {
    switch (cdec.event?.category.id) {
      case "14": //fallthrough
      case "15":
        return cdec.user?.name
          ? formatMessage({ defaultMessage: "User" }) + ": " + cdec.user?.name
          : formatMessage({ defaultMessage: "User" });
      case "29":
        return cdec.customer?.["monitored-signal-strength"]
          ? formatMessage({ defaultMessage: "signal strength" })
          : "";
      case "31":
        return cdec["rfid-token"]?.place
          ? formatMessage({ defaultMessage: "RFID token" }) +
              ": " +
              cdec["rfid-token"]?.place
          : formatMessage({ defaultMessage: "RFID token" });
      case "32":
        return formatMessage({ defaultMessage: "Interval rule" });
      default:
        return cdec.zone?.place
          ? `${formatMessage({ defaultMessage: "Zone" })}: ${cdec.zone.place}`
          : "null";
    }
  };

  const customerDialActions = [
    {
      name: formatMessage({ defaultMessage: "Edit customer" }),
      href: `/alarm/customers/${customerID}`,
    },
    {
      name: formatMessage({ defaultMessage: "Customer events" }),
      href: `/alarm/events?customer=${customerID}&arrived=${encodeURIComponent(
        serializeDateRange(moment().subtract(1, "days")),
      )}`,
    },
  ];

  const coords = latlng
    ? { lat: latlng.lat, lon: latlng.lng }
    : cdec.latitude && cdec.longitude
      ? { lat: cdec.latitude, lon: cdec.longitude }
      : null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flexDirection: "row", display: "flex", flex: 1 }}>
        <Box sx={{ display: "flex", flex: 1, width: "300px" }}>
          <List dense sx={{ paddingBottom: 0, marginBottom: 0 }}>
            <ListItem>
              <ModalListItemText
                color={color ?? cdec.color}
                primary={formatMessage({
                  defaultMessage: "Event code",
                  description: "Cdec felugró részletes nézet eseméykód",
                })}
                secondary={
                  <Inactivate
                    cdec={cdec}
                    disableInactivate={disableInactivate}
                  />
                }
                secondaryTypographyProps={{}}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {`${formatMessage({ defaultMessage: "Customer" })}`}

                    {(cdec.customer?.name || cdec.customer?.address) && (
                      <Tooltip
                        arrow
                        placement="right"
                        title={
                          cdec.customer?.name ? (
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Box>{`${formatMessage({ defaultMessage: "Name" })}: ${cdec.customer?.name ?? "N/A"}`}</Box>
                              <Box>{`${formatMessage({ defaultMessage: "Address" })}: ${cdec.customer?.address ?? "N/A"}`}</Box>
                            </Box>
                          ) : (
                            ""
                          )
                        }
                      >
                        <InfoIcon fontSize="small" sx={{ marginLeft: "8px" }} />
                      </Tooltip>
                    )}
                  </Box>
                }
                secondaryTypographyProps={{
                  component: "div",
                }}
                secondary={
                  customer ? (
                    <SpeedDial
                      ariaLabel="Customer speed dial"
                      FabProps={{
                        size: "small",
                        variant: "extended",
                        sx: {
                          backgroundColor: color ?? cdec.color,
                          "&:hover": {
                            backgroundColor: darken(color, 0.2),
                          },
                          flexShrink: 0,
                          display: "block",
                          alignItems: "center",
                          position: "absolute",
                        },
                      }}
                      icon={cdec.account}
                      direction="right"
                    >
                      {cdec.customer
                        ? customerDialActions.map((action, i) => {
                            return (
                              <MbProSpeedDialAction
                                FabProps={{
                                  size: "small",
                                  variant: "extended",
                                  sx: {
                                    color,
                                    zIndex: 1,

                                    display: "flex",
                                    justifyContent: "start",
                                    left: 40,
                                  },
                                  component: Link,
                                  to: action.href,
                                }}
                                icon={
                                  <Tooltip
                                    title={action.name}
                                    disableHoverListener={
                                      action.name.length < 23
                                    }
                                    sx={{
                                      display: "flex",
                                      alignSelf: "center",
                                    }}
                                  >
                                    <Box
                                      component={"span"}
                                      sx={{
                                        display: "block",
                                        alignItems: "center",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "200px",
                                      }}
                                    >
                                      {action.name}
                                    </Box>
                                  </Tooltip>
                                }
                                key={i}
                              />
                            );
                          })
                        : null}
                    </SpeedDial>
                  ) : (
                    `${cdec.account} - ${formatMessage({ defaultMessage: "Unknown customer" })}`
                  )
                }
              />
            </ListItem>

            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({ defaultMessage: "Helios ID" })}
                secondary={cdec.unitid ?? "N/A"}
              />
            </ListItem>

            <ListItem>
              <ModalListItemText
                color={color}
                primary={`${formatMessage({
                  defaultMessage: "Coordinates",
                })}${
                  coords
                    ? ` (${formatMessage({
                        defaultMessage: "Longitude",
                      })}, ${formatMessage({
                        defaultMessage: "Latitude",
                      })})`
                    : ""
                }`}
                secondary={coords ? <CoordsLabel {...coords} /> : "N/A"}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ display: "flex", flex: 1, width: "300px" }}>
          <List dense sx={{ paddingBottom: 0, marginBottom: 0 }}>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({
                  defaultMessage: "Event time",
                  description: "Cdec felugró részletes nézet küldve ekkor",
                })}
                secondary={formatTimestamp(cdec.sent) ?? "N/A"}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({
                  defaultMessage: "Arrival time",
                  description: "Cdec felugró részletes nézet érkezés",
                })}
                secondary={formatTimestamp(cdec.arrived) ?? "N/A"}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({ defaultMessage: "Partition number" })}
                secondary={
                  cdec.event?.category.id === "29" &&
                  cdec.customer?.["monitored-battery-level"]
                    ? `${cdec["partition-number"]} - ${formatMessage({ defaultMessage: "battery level" })}`
                    : cdec["partition-number"]
                }
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {formatMessage({ defaultMessage: "Zone number" })}
                    {getEventCategory(cdec) !== "null" ? (
                      <Tooltip
                        arrow
                        placement="right"
                        title={getEventCategory(cdec)}
                      >
                        <InfoIcon fontSize="small" sx={{ marginLeft: "8px" }} />
                      </Tooltip>
                    ) : null}
                  </Box>
                }
                secondary={cdec["zone-number"]}
              />
            </ListItem>
            <ListItem>
              <ModalListItemText
                color={color}
                primary={formatMessage({ defaultMessage: "Active" })}
                secondary={
                  <ListItemIcon>
                    {cdec.active ? (
                      <CheckIcon fontSize="small" color="primary" />
                    ) : (
                      <ClearIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                }
              />
            </ListItem>
          </List>
        </Box>
        {viewType === "extended" && <ExtendedCdecModalContent cdec={cdec} />}
      </Box>
      {customer ? (
        <ListItem sx={{ display: "flex", paddingTop: 0 }} dense>
          <ModalListItemText
            color={color}
            primary={formatMessage({ defaultMessage: "Event description" })}
            secondary={cdec["localized-description"] ?? "N/A"}
          />
        </ListItem>
      ) : null}
    </Box>
  );
};

const ExtendedCdecModalContent = ({ cdec }: { cdec: Cdec }) => {
  const { formatMessage } = useIntl();
  const color = cdec.color;

  return (
    <Box sx={{ display: "flex", maxWidth: "300px" }}>
      <Box sx={{ display: "flex" }}>
        <List dense sx={{ paddingBottom: 0, marginBottom: 0 }}>
          <ListItem>
            <ModalListItemText
              color={color}
              primary={formatMessage({ defaultMessage: "Text message" })}
              secondary={cdec["text-msg"] ?? "\xA0"}
            />
          </ListItem>
          <ListItem>
            <ModalListItemText
              color={color}
              primary={formatMessage({
                defaultMessage: "Secondary customer account",
              })}
              secondary={cdec["sec-account"] ?? "N/A"}
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export const Inactivate = ({
  cdec,
  disableInactivate,
}: {
  cdec: Cdec;
  disableInactivate?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { account, "event-code": eventCode } = cdec;
  const color = cdec.color ?? cdec.event?.category.hex;

  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();

  const {
    mutate: disableEvent,
    status,
    error,
  } = useCreate("alarm/disabled-events");

  useEffect(() => {
    if (status === "success") {
      setSnackbarState({
        message: formatMessage(
          {
            defaultMessage:
              "{account} customer's {eventCode} events inactivated",
            description: "Event inactivation success",
          },
          {
            account,
            eventCode,
          },
        ),
      });
    } else if (status === "error") {
      setSnackbarState({
        message: error
          ? getErrorMessage(error)
          : formatMessage({
              defaultMessage: "Something went wrong",
              description: "Error handler default error message",
            }),
        error: true,
      });
    }
  }, [status, account, eventCode, error, formatMessage]);

  const label = formatMessage({
    defaultMessage: "Disable event",
    description: "Cdec felugró részletes nézet inaktiválás",
  });

  return (
    <>
      <SpeedDial
        ariaLabel="Event code speed dial"
        FabProps={{
          size: "small",
          variant: "extended",
          sx: {
            backgroundColor: color,
            "&:hover": {
              backgroundColor: darken(color, 0.2),
            },
            zIndex: 1,
            flexShrink: 0,
          },
        }}
        icon={cdec["event-code"]}
        direction="right"
      >
        <MbProSpeedDialAction
          FabProps={{
            size: "small",
            variant: "extended",
            sx: {
              color,
              zIndex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
              display: "block",
            },
          }}
          disabled={!cdec.customer || !cdec.active || disableInactivate}
          onClick={() => setIsAlertOpen(true)}
          icon={
            <Tooltip title={label} disableHoverListener={label.length < 23}>
              <span>{label}</span>
            </Tooltip>
          }
        />
      </SpeedDial>

      {cdec.customer !== null ? (
        <InactivateDialog
          open={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
          customer={cdec.customer}
          account={cdec.account}
          eventCode={cdec["event-code"]}
          onInactivate={(
            customer: ID,
            eventCode: string,
            tempInactiveTs: string | null,
            inactiveComment: string | null,
          ) => {
            disableEvent({
              customer,
              "event-code": eventCode,
              "temp-inactive-ts": tempInactiveTs,
              "inactive-comment": inactiveComment,
            });
            setIsAlertOpen(false);
          }}
        />
      ) : null}

      <Snackbar
        onClose={() => setSnackbarState({ message: undefined })}
        state={snackbarState}
      />
    </>
  );
};

export type ViewState = "standard" | "extended" | "camera";

const defaultViewState: { $version: number; view: ViewState } = {
  $version: 1.0,
  view: "standard",
};

function parseViewState(json: string) {
  const result = JSON.parse(json) as typeof defaultViewState;
  if (result.$version === defaultViewState.$version) {
    return result;
  } else {
    return defaultViewState;
  }
}

const CdecModal = ({
  viewState,
  intervention,
  isUnacknowledgedEvent,
  cdecID,
  onClose,
  disableInactivate,
  alertReview = {
    isAlertReview: false,
    alertReviewId: undefined,
    reviewFieldsVisibility: false,
    handleAlert() {},
    autocompleteOptions: [],
    alertReviewTaskDescription: "",
  },
}: CdecModalProps) => {
  const { data: cdecAll } = useCdec(cdecID);
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const hasInitializedViewState = useRef(false);

  const [customizedViewState, setCustomizedViewState] = useStorageState(
    "eventModalView",
    defaultViewState,
    false,
    parseViewState,
  );

  const getTaskReviewData = (
    intervention: Intervention | undefined,
    cdec: Cdec | undefined,
  ) => {
    if (
      !intervention ||
      !intervention["alert-review-tasks"] ||
      !cdec ||
      !cdec.event
    ) {
      return [];
    }

    const cdecEventId = cdec.event.id;
    const cdecEventArrived = cdec.arrived;

    return intervention["alert-review-tasks"]
      .filter(
        (task) =>
          task.cdec?.event === cdecEventId &&
          task.cdec?.arrived === cdecEventArrived,
      )
      .map((task) => ({
        reason: task.details?.reason || null,
        description: task.description || "",
      }));
  };

  const handleViewChange = () => {
    if (customizedViewState.view === "standard") {
      setCustomizedViewState({ ...customizedViewState, view: "extended" });
    } else {
      setCustomizedViewState({ ...customizedViewState, view: "standard" });
    }
  };

  const handleSubmit = () => {
    const inputValue = inputRef.current?.value;
    if (
      inputValue &&
      inputValue.trim() !== "" &&
      alertReview.handleAlert &&
      alertReview.alertReviewId !== undefined
    ) {
      alertReview.handleAlert(false, alertReview.alertReviewId, inputValue);
      handleStopVideo();
      onClose();
    }
  };

  const handleStopVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = "";
    }
    if (imgRef.current) {
      imgRef.current.src = "";
    }
  };

  const handleClose: DialogProps["onClose"] = (reason) => {
    if (reason && reason === "backdropClick") return;
    handleStopVideo();
    onClose();
  };

  function stripHTMLTags(htmlString: string = "") {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    if (!hasInitializedViewState.current && viewState) {
      if (
        viewState.view === "standard" &&
        customizedViewState.view === "extended"
      ) {
        return;
      }
      setCustomizedViewState(viewState);
      hasInitializedViewState.current = true;
    }
  }, [viewState, customizedViewState, setCustomizedViewState]);

  return (
    <Dialog disableScrollLock open onClose={handleClose} maxWidth={false}>
      {!!cdecAll && (
        <>
          <DialogTitle
            sx={{
              backgroundColor: cdecAll.color ?? cdecAll.event?.category.hex,
              padding: (theme) => theme.spacing(2),
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            component={"div"}
          >
            <Box component={"span"} sx={{ width: "80px" }}></Box>
            <Box
              component={"span"}
              flexGrow={1}
              flexShrink={1}
              flexBasis={1}
              position="relative"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {cdecAll.event?.category["localized-description"] ||
                `${formatMessage({ defaultMessage: "Unknown event" })} ${cdecAll["event-code"]}`}
            </Box>
            <Box
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                flexGrow: 0,
                display: "flex",
                width: "80px",
              }}
              component={"span"}
            >
              <Tooltip
                title={
                  customizedViewState.view === "standard"
                    ? formatMessage({ defaultMessage: "Extended view" })
                    : formatMessage({ defaultMessage: "Standard view" })
                }
              >
                <span>
                  {customizedViewState.view !== "camera" && (
                    <IconButton onClick={handleViewChange}>
                      {customizedViewState.view === "standard" ? (
                        <FullscreenIcon />
                      ) : (
                        <CloseFullscreenIcon />
                      )}
                    </IconButton>
                  )}
                </span>
              </Tooltip>
              {customizedViewState.view !== "camera" && (
                <Tooltip title={formatMessage({ defaultMessage: "Close" })}>
                  <span>
                    <IconButton
                      onClick={() => {
                        handleStopVideo();
                        onClose();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          </DialogTitle>
          <DialogContent>
            {alertReview.isAlertReview && cdecAll.customer?.cameras && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  p: 2,
                  pb: 1,
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                  {formatMessage({
                    defaultMessage: "View",
                    description: "cdec riasztás bírálat",
                  })}
                </Typography>
                <Chip
                  label={formatMessage({ defaultMessage: "Standard" })}
                  sx={{ fontWeight: "500" }}
                  onClick={() =>
                    setCustomizedViewState({
                      view: "standard",
                      $version: 1.0,
                    })
                  }
                  disabled={customizedViewState.view === "standard"}
                ></Chip>

                <Chip
                  label={formatMessage({ defaultMessage: "Camera" })}
                  sx={{ fontWeight: "500" }}
                  onClick={() =>
                    setCustomizedViewState({ view: "camera", $version: 2.0 })
                  }
                  disabled={customizedViewState.view === "camera"}
                ></Chip>
              </Box>
            )}
            <Box sx={{ display: "flex" }}>
              {(customizedViewState.view === "standard" ||
                customizedViewState.view === "extended") && (
                <CdecModalContents
                  cdec={cdecAll}
                  disableInactivate={!!disableInactivate}
                  viewType={customizedViewState.view}
                />
              )}
              {alertReview.isAlertReview && (
                <Box
                  sx={{
                    display:
                      customizedViewState.view !== "camera" ? "none" : "block",
                  }}
                >
                  <CameraModal
                    cdec={cdecAll}
                    onClose={onClose}
                    handleStopVideo={handleStopVideo}
                    videoRef={videoRef}
                    imgRef={imgRef}
                  ></CameraModal>
                </Box>
              )}
            </Box>
            {alertReview.isAlertReview && alertReview.reviewFieldsVisibility
              ? cdecAll.customer?.cameras && (
                  <Box sx={{ p: 2 }}>
                    <Grid container spacing={4} alignItems="center">
                      <Grid item xs={6}>
                        <Box display="inline-flex">
                          <Button
                            variant="contained"
                            startIcon={<DoneIcon />}
                            sx={{
                              justifyContent: "flex-start",
                              textAlign: "left",
                              mt: 1,
                              minWidth: 0,
                            }}
                            onClick={() => {
                              if (
                                alertReview.handleAlert &&
                                alertReview.alertReviewId !== undefined
                              ) {
                                alertReview.handleAlert(
                                  true,
                                  alertReview.alertReviewId,
                                );
                              }
                            }}
                          >
                            {formatMessage({
                              defaultMessage: "Action required",
                            })}
                          </Button>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Tooltip
                          arrow
                          title={
                            alertReview.alertReviewTaskDescription !==
                            "<p></p>\n"
                              ? stripHTMLTags(
                                  alertReview.alertReviewTaskDescription,
                                )
                              : formatMessage({
                                  defaultMessage:
                                    "There is no description for the task",
                                })
                          }
                        >
                          <Autocomplete
                            disablePortal
                            freeSolo
                            options={alertReview.autocompleteOptions ?? []}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputRef={inputRef}
                                variant="standard"
                                rows={1}
                                placeholder={formatMessage({
                                  defaultMessage: "Alarm cancel reason",
                                })}
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <CloseIcon color="error" sx={{ mr: 1 }} />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <>
                                      {params.InputProps?.endAdornment}
                                      <Button
                                        variant="text"
                                        endIcon={<SendIcon />}
                                        sx={{
                                          p: 1,
                                          minWidth: "30px",
                                          color: (theme) =>
                                            theme.palette.primary.main,
                                        }}
                                        onClick={handleSubmit}
                                      />
                                    </>
                                  ),
                                }}
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    if (
                                      !document.querySelector(
                                        ".MuiAutocomplete-popper",
                                      )
                                    ) {
                                      event.preventDefault();
                                      handleSubmit();
                                    }
                                  }
                                }}
                              />
                            )}
                            sx={{ width: "100%" }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                )
              : getTaskReviewData(intervention, cdecAll).some(
                  (value) => value.reason && value.reason?.length > 0,
                ) && (
                  <Box
                    sx={{
                      p: 2,
                    }}
                  >
                    <Divider sx={{ mb: 2 }}></Divider>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Typography>
                        <SvgIcon
                          sx={{ fontWeight: "800" }}
                          viewBox="-1 -4 22 22"
                          component={AlertReviewTasksIcon}
                        />
                      </Typography>
                      <Typography sx={{ ml: 0.5, fontSize: "0.875rem" }}>
                        {formatMessage({ defaultMessage: "Alarm review" })}
                      </Typography>
                    </Box>

                    {getTaskReviewData(intervention, cdecAll).map(
                      (value, index) =>
                        value.reason && (
                          <Card
                            key={index}
                            elevation={0}
                            sx={{
                              background: "#eeeeee",
                              mt: 2,
                              "& .MuiCardContent-root:last-child": {
                                paddingBottom: 2,
                              },
                            }}
                          >
                            <CardContent>
                              <Typography
                                sx={{ fontWeight: "500", fontSize: "0.875rem" }}
                              >
                                {formatMessage({ defaultMessage: "Task" })}
                              </Typography>
                              <Typography
                                sx={{ maxWidth: "500px", fontSize: "0.875rem" }}
                              >
                                {stripHTMLTags(value.description)}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  mt: 1,
                                  fontSize: "0.875rem",
                                }}
                              >
                                {formatMessage({
                                  defaultMessage: "Alarm cancel reason",
                                })}
                              </Typography>
                              <Typography
                                sx={{ maxWidth: "500px", fontSize: "0.875rem" }}
                              >
                                {value.reason}
                              </Typography>
                            </CardContent>
                          </Card>
                        ),
                    )}
                  </Box>
                )}

            {isUnacknowledgedEvent !== undefined && isUnacknowledgedEvent && (
              <CustomAlert
                sx={{ m: 2 }}
                text={formatMessage({ defaultMessage: "Unacknowledged event" })}
                alertType={"info"}
              />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default CdecModal;

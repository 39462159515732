import { Button } from "@mb-pro-ui/components";
import { JsonapiError } from "@mb-pro-ui/utils";
import {
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  lighten,
} from "@mui/material";
import { ReactNode } from "react";
import { useIntl } from "react-intl";
import { UseMutateFunction } from "react-query";

interface DeleteDialogProps {
  open: boolean;
  handleClose: () => void;
  resourceId: string | undefined;
  resourceName: string;
  deleteResource:
    | UseMutateFunction<string, JsonapiError, string, unknown>
    | ((id?: string) => void | Promise<any>);
  dialogText?: ReactNode;
  dialogTitle?: ReactNode;
}

const DeleteDialog = ({
  open,
  resourceId,
  resourceName,
  handleClose,
  deleteResource,
  dialogText,
  dialogTitle,
}: DeleteDialogProps) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle sx={{ backgroundColor: "error.main" }}>
        {dialogTitle ??
          formatMessage(
            { defaultMessage: "Delete {resource}" },
            { resource: resourceName ?? resourceId },
          )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="error">
          {dialogText ??
            formatMessage(
              {
                defaultMessage: "Are you sure you want to delete {resource}?",
              },
              { resource: resourceName ?? resourceId },
            )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            color: "error.main",
            border: (theme: any) => `1px solid ${theme.palette.error.main}`,
            "&:hover": {
              border: (theme: any) =>
                `1px solid ${lighten(theme.palette.error.main, 0.5)}`,
              backgroundColor: (theme: any) =>
                lighten(theme.palette.error.main, 0.9),
            },
          }}
        >
          {formatMessage({ defaultMessage: "No" })}
        </Button>
        <Button
          onClick={() => {
            resourceId && deleteResource(resourceId);
            handleClose();
          }}
          sx={{
            backgroundColor: "error.main",
            "&:hover": {
              backgroundColor: (theme: any) =>
                darken(theme.palette.error.main, 0.2),
            },
          }}
        >
          {formatMessage({ defaultMessage: "Yes" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;

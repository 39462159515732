import { Customer } from "@/api/alarm";
import { VideoCamera } from "@/api/alarm/video-camera";
import { VideoServer } from "@/api/alarm/video-servers";
import { useGetOne } from "@mb-pro-ui/utils";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import InfoIcon from "@mui/icons-material/Info";
import LaunchIcon from "@mui/icons-material/Launch";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import useTimestampFormat from "../../../locales/useTimestampFormat";
import { DialogCloseButton } from "../../../map/MapMarkerDialog";
import CustomAlert from "../../form/CustomAlert";
import { Cdec } from "../cdec/useCdec";
import {
  fetchArchivedSnapshot,
  fetchArchivedVideo,
  fetchMainStream,
  getArchiveBoundaries,
  getFrames,
  getLiveStream,
} from "./CustomerCameras";
import { getCameraPropertyValues, processCameras } from "./utils/utils";

export type ExtendedVideoCamera = VideoCamera & {
  videoServer: VideoServer;
};

const InfoPanel = ({
  cdec,
  customerCameras,
}: {
  cdec: Cdec;
  customerCameras: ExtendedVideoCamera[];
}) => {
  const { formatMessage } = useIntl();
  const { formatTimestamp } = useTimestampFormat();
  const openLiveCamerasInMonitorApp = async () => {
    const url = "http://localhost:8090/navigate";
    const channels = customerCameras.map((camera: ExtendedVideoCamera) => ({
      serverId: camera.videoServer.config.luxriotMonitorChannelId,
      id: camera.config.cameraId,
    }));

    const body = {
      mode: "live",
      channels: channels,
    };

    try {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const openArchiveCamerasInMonitorApp = async () => {
    const url = "http://localhost:8090/navigate";
    const channels = customerCameras.map((camera: ExtendedVideoCamera) => ({
      serverId: camera.videoServer.config.luxriotMonitorChannelId,
      id: camera.config.cameraId,
    }));

    const body = {
      mode: "playback",
      channels: channels,
    };

    try {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Grid xs={12} item>
      <Box
        sx={{
          background: "#e5e5e5",
          display: "flex",
          alignItems: "center",
          gap: "25px",
          height: "39px",
          borderTopLeftRadius: "100px",
          borderBottomLeftRadius: "100px",
          borderTopRightRadius: "50px",
          borderBottomRightRadius: "50px",
        }}
      >
        <InfoIcon
          sx={{
            width: "43px",
            height: "43px",
            position: "relative",
            right: "2px",
            color: cdec.color,
          }}
        />
        <Box>
          <Typography
            sx={{
              color: cdec.color,
              fontSize: "12px",
            }}
          >
            {formatMessage({
              defaultMessage: "Event time",
            })}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {formatTimestamp(cdec["sent"])}
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box>
          <Typography
            sx={{
              color: cdec.color,
              fontSize: "12px",
            }}
          >
            {formatMessage({
              defaultMessage: "Arrival time",
            })}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {formatTimestamp(cdec["arrived"])}
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box>
          <Typography
            sx={{
              color: cdec.color,
              fontSize: "12px",
            }}
          >
            {formatMessage({
              defaultMessage: "Zone",
            })}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {cdec["zone-number"]}
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box>
          <Typography
            sx={{
              color: cdec.color,
              fontSize: "12px",
            }}
          >
            {formatMessage({
              defaultMessage: "Event code",
            })}
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            {cdec["event-code"]}
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            sx={{
              color: cdec.color,
              fontSize: "12px",
            }}
          >
            {formatMessage({
              defaultMessage: "Event description",
            })}
          </Typography>
          <Tooltip title={cdec["localized-description"]}>
            <Typography
              sx={{
                fontSize: "12px",
                maxWidth: "170px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {cdec["localized-description"]}
            </Typography>
          </Tooltip>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            mr: 3,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Tooltip
            title={formatMessage({
              defaultMessage:
                "Opening cameras in the Luxriot monitor application (live)",
            })}
          >
            <IconButton sx={{ display: "flex" }}>
              <LaunchIcon
                onClick={openLiveCamerasInMonitorApp}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={formatMessage({
              defaultMessage:
                "Opening cameras in the Luxriot monitor application (archive)",
            })}
          >
            <IconButton sx={{ display: "flex" }}>
              <RestartAltIcon
                onClick={openArchiveCamerasInMonitorApp}
                fontSize="small"
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Grid>
  );
};

const SnapshotAndLiveStream = ({
  viewMode,
  setViewMode,
  isArchiveValid,
  archivedVideo,
  snapshotUrl,
  fullScreenState,
  toggleFullScreen,
  selectedContentType,
  liveStreamUrl,
  setLiveStreamUrl,
  videoRef,
  imgRef,
  mainContainerStyle,
  getToggleStyle,
  archiveVideoRef,
  setArchivedVideoCurrentTime,
  selectedCamera,
  videoServerConfig,
  customerCamerasAndServers,
  cdec,
  setArchivedVideo,
}: {
  viewMode: string;
  setViewMode: React.Dispatch<React.SetStateAction<string>>;
  isArchiveValid: () => boolean;
  archivedVideo:
    | {
        url: string;
        xStreamStartTime: number;
      }
    | undefined;
  snapshotUrl: string;
  fullScreenState: {
    video: boolean;
    snapshot: boolean;
  };
  toggleFullScreen: (type: "video" | "snapshot") => void;
  selectedContentType: string | undefined;
  liveStreamUrl: string;
  setLiveStreamUrl: React.Dispatch<React.SetStateAction<string>>;
  videoRef: React.RefObject<HTMLVideoElement>;
  imgRef: React.RefObject<HTMLImageElement>;
  mainContainerStyle: (isFullScreen: boolean) => React.CSSProperties;
  getToggleStyle: (
    isFullScreen: boolean,
    otherFullScreen: boolean,
  ) => React.CSSProperties;
  archiveVideoRef: React.RefObject<HTMLVideoElement>;
  setArchivedVideoCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  selectedCamera: {
    id: number | undefined;
    name: string | undefined;
  };
  videoServerConfig: {
    url: string;
    username: string;
    password: string;
  };
  customerCamerasAndServers: VideoCamera[];
  cdec: Cdec;
  setArchivedVideo: React.Dispatch<
    React.SetStateAction<
      | {
          url: string;
          xStreamStartTime: number;
        }
      | undefined
    >
  >;
}) => {
  const { formatMessage } = useIntl();

  const resetLiveStream = useCallback(async () => {
    const url = await getLiveStream(
      selectedCamera.id,
      videoServerConfig.url,
      videoServerConfig.username,
      videoServerConfig.password,
    );
    setLiveStreamUrl(url ?? "");
  }, [
    selectedCamera.id,
    videoServerConfig.url,
    videoServerConfig.username,
    videoServerConfig.password,
    setLiveStreamUrl,
  ]);

  const buttons = [
    {
      value: "archive-snapshot",
      label: formatMessage({ defaultMessage: "Snapshot" }),
      disabled: !isArchiveValid(),
    },
    {
      value: "archive-video",
      label: formatMessage({ defaultMessage: "Archive video" }),
      disabled:
        selectedContentType === undefined ||
        selectedContentType === "image/jpeg" ||
        !isArchiveValid(),
    },
  ];

  const handleButtonClick = async (value: string) => {
    setViewMode(value);
    if (value === "archive-video" && selectedContentType !== "image/jpeg") {
      await fetchArchivedVideo(
        selectedCamera.id,
        videoServerConfig.url,
        videoServerConfig.username,
        videoServerConfig.password,
        setArchivedVideo,
        Date.parse(cdec.sent) - 9000,
      );
    }
  };

  return (
    <Grid sx={{ pt: 5 }} container>
      <Grid item xs={6}>
        <Box sx={{ pb: 1, pr: "5px", display: "flex", gap: "8px" }}>
          {buttons.map((button, index) => (
            <Chip
              key={index}
              onClick={() => handleButtonClick(button.value)}
              disabled={button.disabled}
              sx={{
                color: viewMode === button.value ? "#ffffff" : "#4f7399",
                backgroundColor: (theme) =>
                  viewMode === button.value
                    ? theme.palette.primary.main
                    : "default",
                "&:hover": {
                  color: "#4f7399",
                },
              }}
              label={button.label}
            />
          ))}
        </Box>
        {isArchiveValid() ? (
          <Box sx={{ pr: "5px", position: "relative" }}>
            {viewMode === "archive-video" &&
            selectedContentType !== "image/jpeg" ? (
              archivedVideo !== undefined ? (
                <video
                  ref={archiveVideoRef}
                  controls
                  style={mainContainerStyle(fullScreenState.snapshot)}
                  onTimeUpdate={() =>
                    setArchivedVideoCurrentTime(
                      archiveVideoRef.current!.currentTime,
                    )
                  }
                >
                  <source src={archivedVideo.url} />
                </video>
              ) : (
                <Box sx={mainContainerStyle(false)}>
                  <CustomAlert
                    alertType="error"
                    text={formatMessage({
                      defaultMessage: "No archive available",
                    })}
                  />
                </Box>
              )
            ) : (
              viewMode === "archive-snapshot" && (
                <>
                  <img
                    style={mainContainerStyle(fullScreenState.snapshot)}
                    src={snapshotUrl}
                    alt=""
                  />
                  <FullScreenToggle
                    isFullScreen={fullScreenState.snapshot}
                    onToggle={() => toggleFullScreen("snapshot")}
                    style={getToggleStyle(
                      fullScreenState.snapshot,
                      fullScreenState.video,
                    )}
                  />
                </>
              )
            )}
          </Box>
        ) : (
          <Box sx={mainContainerStyle(false)}>
            <CustomAlert
              alertType="error"
              text={formatMessage({ defaultMessage: "No archive available" })}
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={6}>
        {selectedContentType === "video/mp4" ||
        selectedContentType === "video/webm" ? (
          <LiveVideo
            src={liveStreamUrl}
            videoRef={videoRef}
            getVideoStyle={mainContainerStyle}
            isFullScreen={fullScreenState.video}
            toggleFullScreen={() => toggleFullScreen("video")}
            toggleStyle={getToggleStyle(
              fullScreenState.video,
              fullScreenState.snapshot,
            )}
            onTimeout={resetLiveStream}
          />
        ) : selectedContentType === "image/jpeg" ? (
          <LiveImage
            src={liveStreamUrl}
            imgRef={imgRef}
            getVideoStyle={mainContainerStyle}
            isFullScreen={fullScreenState.video}
            toggleFullScreen={() => toggleFullScreen("video")}
            toggleStyle={getToggleStyle(
              fullScreenState.video,
              fullScreenState.snapshot,
            )}
          />
        ) : (
          <>
            <LiveHeader />
            <Box sx={{ pl: "5px" }}>
              <Box sx={mainContainerStyle(false)}>
                <CustomAlert
                  alertType="error"
                  text={formatMessage(
                    { defaultMessage: "{camera} is not available" },
                    {
                      camera: customerCamerasAndServers.find(
                        (c) => c.name === selectedCamera.name,
                      )?.name,
                    },
                  )}
                />
              </Box>
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

const LiveHeader = ({ children }: { children?: React.ReactNode }) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        height: "40px",
        pb: 1,
        display: "flex",
        alignItems: "center",
        pl: "5px",
      }}
    >
      <Typography sx={{ fontSize: "13px" }}>
        <CircleIcon
          sx={{ fontSize: "7px", color: "red", mr: "5px", mb: "1px" }}
        />
        {formatMessage({ defaultMessage: "Live" })}
      </Typography>
      {children}
    </Box>
  );
};

const LiveImage = ({
  src,
  imgRef,
  getVideoStyle,
  isFullScreen,
  toggleFullScreen,
  toggleStyle,
}: {
  src: string;
  imgRef: React.RefObject<HTMLImageElement>;
  getVideoStyle: (isFullScreen: boolean) => React.CSSProperties;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  toggleStyle: React.CSSProperties;
}) => (
  <>
    <LiveHeader />
    {src ? (
      <Box style={{ position: "relative" }}>
        <img
          ref={imgRef}
          src={src}
          alt="Video stream"
          style={getVideoStyle(isFullScreen)}
        />
        <FullScreenToggle
          isFullScreen={isFullScreen}
          onToggle={toggleFullScreen}
          style={toggleStyle}
        />
      </Box>
    ) : null}
  </>
);

const LiveVideo = ({
  src,
  videoRef,
  getVideoStyle,
  isFullScreen,
  toggleFullScreen,
  toggleStyle,
  onTimeout,
}: {
  src: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  getVideoStyle: (isFullScreen: boolean) => React.CSSProperties;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  toggleStyle: React.CSSProperties;
  onTimeout?: () => void;
}) => {
  const [currentTime, setCurrentTime] = useState("");
  const [duration, setDuration] = useState("");

  const onTimeoutRef = useRef(onTimeout);
  useEffect(() => {
    onTimeoutRef.current = onTimeout;
  }, [onTimeout]);

  useEffect(() => {
    if (!src) {
      return;
    }

    const timeout = setTimeout(() => {
      onTimeoutRef.current?.();
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [src, currentTime]);

  return (
    <>
      <LiveHeader>
        {currentTime || duration ? (
          <Typography sx={{ fontSize: "11px", ml: 1, fontWeight: 500 }}>
            {[currentTime, duration].filter(Boolean).join(" / ")}
          </Typography>
        ) : (
          <CircularProgress sx={{ ml: 1 }} size="12px" />
        )}
      </LiveHeader>
      {src ? (
        <Box sx={{ position: "relative" }}>
          <video
            autoPlay
            disablePictureInPicture
            ref={videoRef}
            style={getVideoStyle(isFullScreen)}
            onTimeUpdate={(e) =>
              setCurrentTime(formatTime(e.currentTarget.currentTime))
            }
            onDurationChange={(e) =>
              setDuration(formatTime(e.currentTarget.duration))
            }
          >
            <source src={src} />
          </video>
          <FullScreenToggle
            isFullScreen={isFullScreen}
            onToggle={toggleFullScreen}
            style={toggleStyle}
          />
        </Box>
      ) : null}
    </>
  );
};

const SliderAndTab = ({
  time,
  customerCamerasAndServers,
  viewMode,
  cdec,
  handleTimeChange,
  isArchiveValid,
  setSelectedCamera,
  canvasRef,
  archiveVideoRef,
  archivedVideo,
  archivedVideoCurrentTime,
  frame,
  customerCameras,
  selectedCamera,
}: {
  time: number;
  customerCamerasAndServers: VideoCamera[];
  viewMode: string;
  cdec: Cdec;
  handleTimeChange: (newValue: number) => void;
  isArchiveValid: () => boolean;
  setSelectedCamera: React.Dispatch<
    React.SetStateAction<{ id: number | undefined; name: string | undefined }>
  >;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  archiveVideoRef: React.RefObject<HTMLVideoElement>;
  archivedVideo:
    | {
        url: string;
        xStreamStartTime: number;
      }
    | undefined;
  archivedVideoCurrentTime: number;
  frame: Record<string, number> | null;
  customerCameras: {
    withinTheZone: ExtendedVideoCamera[];
    outOfZone: ExtendedVideoCamera[];
  };
  selectedCamera: {
    id: number | undefined;
    name: string | undefined;
  };
}) => {
  const { formatMessage } = useIntl();
  const { formatTimestamp } = useTimestampFormat();

  const cameraNames = customerCameras.withinTheZone.map(
    (camera) => camera.name,
  );

  const captureImage = () => {
    if (archiveVideoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        context.drawImage(
          archiveVideoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height,
        );
        const image = canvasRef.current.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = image;
        link.download = `${Date.now()}.png`;
        link.click();
      }
    }
  };

  const calculateTimeDifference = () => {
    if (archivedVideo !== undefined) {
      const currentTime =
        archivedVideo.xStreamStartTime +
        Math.round(archivedVideoCurrentTime * 1000);
      const differenceInSeconds = Math.round(
        (currentTime - Date.parse(cdec.sent)) / 1000,
      );
      return (
        (differenceInSeconds >= 0 ? "+" : "") +
        differenceInSeconds +
        formatMessage({ defaultMessage: "s" })
      );
    }
    return null;
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    const selectedCameraObj = customerCamerasAndServers.find(
      (camera) => camera.name === newValue,
    );

    setSelectedCamera({
      id: selectedCameraObj?.config.cameraId,
      name: selectedCameraObj?.name,
    });
  };

  const handlePrevClick = () => {
    if (selectedCamera.name !== undefined) {
      const currentIndex = cameraNames.indexOf(selectedCamera.name);
      const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
      const prevCamera = customerCamerasAndServers.find(
        (camera) => camera.name === cameraNames[newIndex],
      );

      setSelectedCamera({
        id: prevCamera?.config.cameraId,
        name: prevCamera?.name,
      });
    } else {
      const firstCamera = customerCamerasAndServers.find(
        (camera) => camera.name === cameraNames[0],
      );

      setSelectedCamera({
        id: firstCamera?.config.cameraId,
        name: firstCamera?.name,
      });
    }
  };

  const handleNextClick = () => {
    if (selectedCamera.name !== undefined) {
      const currentIndex = cameraNames.indexOf(selectedCamera.name);
      const newIndex =
        currentIndex < cameraNames.length - 1 ? currentIndex + 1 : 0;
      const nextCamera = customerCamerasAndServers.find(
        (camera) => camera.name === cameraNames[newIndex],
      );

      setSelectedCamera({
        id: nextCamera?.config.cameraId,
        name: nextCamera?.name,
      });
    } else {
      const firstCamera = customerCamerasAndServers.find(
        (camera) => camera.name === cameraNames[0],
      );

      setSelectedCamera({
        id: firstCamera?.config.cameraId,
        name: firstCamera?.name,
      });
    }
  };

  useEffect(() => {
    if (selectedCamera.name && customerCamerasAndServers) {
      const camera = customerCamerasAndServers.find(
        (camera) => camera.name === selectedCamera.name,
      );

      if (
        camera?.config.cameraId !== selectedCamera.id ||
        camera?.name !== selectedCamera.name
      ) {
        setSelectedCamera({
          id: camera?.config.cameraId,
          name: camera?.name,
        });
      }
    } else if (
      selectedCamera.id !== undefined ||
      selectedCamera.name !== undefined
    ) {
      setSelectedCamera({
        id: undefined,
        name: undefined,
      });
    }
  }, [selectedCamera, customerCamerasAndServers, setSelectedCamera]);

  useEffect(() => {
    if (
      selectedCamera.name === undefined &&
      customerCameras?.withinTheZone?.length > 0
    ) {
      const firstCamera = customerCameras.withinTheZone[0];

      setSelectedCamera({
        id: firstCamera.config.cameraId,
        name: firstCamera.name,
      });
    }
  }, [selectedCamera.name, customerCameras, setSelectedCamera]);

  return (
    <Grid container sx={{ pt: 1 }} xs={12} item>
      <Grid container>
        <Grid
          sx={{
            display: viewMode === "archive-video" ? "flex" : "block",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={6}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pr: "5px",
              gap: "50px",
            }}
          >
            <Slider
              track={false}
              value={time}
              step={1000}
              size="small"
              sx={{
                display:
                  viewMode === "archive-snapshot" && isArchiveValid()
                    ? "block"
                    : "none",
                m: "0",
                width: "50%",
                padding: "19px 0",
              }}
              marks={[
                {
                  value: Date.parse(cdec.sent) - 10000,
                  label: `-10${formatMessage({
                    defaultMessage: "s",
                  })}`,
                },
                {
                  value: Date.parse(cdec.sent),
                  label: "|",
                },
                {
                  value: Date.parse(cdec.sent) + 10000,
                  label: `+10${formatMessage({
                    defaultMessage: "s",
                  })}`,
                },
              ]}
              min={Date.parse(cdec.sent) - 10000}
              max={Date.parse(cdec.sent) + 10000}
              onChange={(_, newValue) => handleTimeChange(newValue as number)}
            />
            {frame && isArchiveValid() && viewMode === "archive-snapshot" && (
              <span style={{ fontWeight: "500", fontSize: "13px" }}>
                {new Date(frame.nextFrameTime).toLocaleString()}
              </span>
            )}
          </Box>
          <Box
            sx={{
              display: viewMode === "archive-video" ? "flex" : "none",
              alignItems: "center",
              gap: "40px",
            }}
          >
            <Button sx={{ p: 0, fontSize: "13px" }} onClick={captureImage}>
              {formatMessage({ defaultMessage: "Download current image" })}
            </Button>
            <Typography sx={{ fontWeight: "500", fontSize: "13px" }}>
              {archivedVideo !== undefined
                ? `${formatTimestamp(cdec.sent)} (${calculateTimeDifference()})`
                : null}
            </Typography>
            <canvas
              ref={canvasRef}
              width="1280"
              height="720"
              style={{ display: "none" }}
            ></canvas>
          </Box>
        </Grid>
        <Grid sx={{}} item xs={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <IconButton
              onClick={handlePrevClick}
              disabled={
                selectedCamera.name === undefined ||
                cameraNames.indexOf(selectedCamera.name) === 0
              }
              size="large"
            >
              <ArrowLeft />
            </IconButton>
            <Select
              value={
                selectedCamera.name !== undefined ? selectedCamera.name : ""
              }
              onChange={handleSelectChange}
              variant="standard"
              sx={{ minWidth: "100px" }}
            >
              <ListSubheader>
                {formatMessage({ defaultMessage: "In-zone camera list" })}
              </ListSubheader>
              {customerCameras.withinTheZone.map((camera) => (
                <MenuItem
                  key={`${camera.config.cameraId}-${camera.name}`}
                  value={camera.name}
                  style={{
                    backgroundColor:
                      selectedCamera.name === camera.name
                        ? "#2587bc"
                        : "inherit",
                    color:
                      selectedCamera.name === camera.name ? "#fff" : "#515151",
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: (theme) =>
                        selectedCamera.name === camera.name
                          ? theme.palette.primary.main
                          : "lightgrey",
                    },
                  }}
                >
                  {camera.name}
                </MenuItem>
              ))}
              <ListSubheader>
                {formatMessage({ defaultMessage: "Out-of-zone camera list" })}
              </ListSubheader>
              {customerCameras.outOfZone.map((camera) => (
                <MenuItem
                  key={`${camera.config.cameraId}-${camera.name}`}
                  value={camera.name}
                  style={{
                    backgroundColor:
                      selectedCamera.name === camera.name
                        ? "#4f7399"
                        : "inherit",
                    color:
                      selectedCamera.name === camera.name ? "#fff" : "#515151",
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor:
                        selectedCamera.name === camera.name
                          ? "#4f7399"
                          : "lightgrey",
                    },
                  }}
                >
                  {camera.name}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              onClick={handleNextClick}
              disabled={
                selectedCamera.name === undefined ||
                cameraNames.indexOf(selectedCamera.name) ===
                  cameraNames.length - 1
              }
              size="large"
            >
              <ArrowRight />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FullScreenToggle = ({
  isFullScreen,
  onToggle,
  style,
}: {
  isFullScreen: boolean;
  onToggle: () => void;
  style: React.CSSProperties;
}) => (
  <>
    {isFullScreen ? (
      <FullscreenExitIcon onClick={onToggle} sx={style} />
    ) : (
      <FullscreenIcon onClick={onToggle} sx={style} />
    )}
  </>
);

const CameraModal = ({
  cdec,
  onClose,
  handleStopVideo,
  videoRef,
  imgRef,
}: {
  cdec: Cdec;
  onClose(): void;
  handleStopVideo(): void;
  videoRef: React.RefObject<HTMLVideoElement>;
  imgRef: React.RefObject<HTMLImageElement>;
}) => {
  const fields = {
    customers: ["cameras", "video-servers"],
  } as const;

  const include = {
    cameras: {},
    "video-servers": {},
  } as const;

  const { data: customerCamerasAndServers } = useGetOne<Customer>(
    "alarm/customers",
    cdec.customer?.id as string,
    {
      fields,
      include,
      refetchOnWindowFocus: false,
    },
  );

  const [selectedCamera, setSelectedCamera] = useState<{
    id: number | undefined;
    name: string | undefined;
  }>({ id: undefined, name: undefined });

  const [customerCameras, setCustomerCameras] = useState({
    withinTheZone: [] as ExtendedVideoCamera[],
    outOfZone: [] as ExtendedVideoCamera[],
  });

  const [snapshotUrl, setSnapshotUrl] = useState<string>("");
  const [selectedContentType, setSelectedContentType] = useState<
    string | undefined
  >(undefined);
  const [liveStreamUrl, setLiveStreamUrl] = useState<string>("");
  const [fullScreenState, setFullScreenState] = useState({
    video: false,
    snapshot: false,
  });
  const [viewMode, setViewMode] = useState<string>("");
  const [archivedVideo, setArchivedVideo] = useState<
    { url: string; xStreamStartTime: number } | undefined
  >(undefined);
  const [time, setTime] = useState<number>(Date.parse(cdec.sent));
  const [frame, setFrame] = useState<Record<string, number> | null>(null);
  const [boundaries, setBoundaries] = useState<{
    from: number;
    to: number;
  } | null>(null);
  const [isTabVisible, setIsTabVisible] = useState(
    document.visibilityState === "visible",
  );
  const [archivedVideoCurrentTime, setArchivedVideoCurrentTime] =
    useState<number>(0);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const archiveVideoRef = useRef<HTMLVideoElement>(null);

  const videoServerConfig = useMemo(() => {
    const url = getCameraPropertyValues(
      selectedCamera.id,
      selectedCamera.name,
      "videoServer.config.url",
      customerCameras,
    );
    const username = getCameraPropertyValues(
      selectedCamera.id,
      selectedCamera.name,
      "videoServer.config.username",
      customerCameras,
    );
    const password = getCameraPropertyValues(
      selectedCamera.id,
      selectedCamera.name,
      "videoServer.config.password",
      customerCameras,
    );

    return { url, username, password };
  }, [selectedCamera.id, selectedCamera.name, customerCameras]);

  useEffect(() => {
    if (customerCamerasAndServers) {
      const videoServerMap = new Map<string, VideoServer>(
        customerCamerasAndServers["video-servers"]?.map((server) => [
          server.id,
          server,
        ]),
      );
      const withinTheZone = processCameras(
        customerCamerasAndServers.cameras || [],
        videoServerMap,
        cdec["zone-number"],
        true,
        cdec,
      );
      const outOfZone = processCameras(
        customerCamerasAndServers.cameras || [],
        videoServerMap,
        cdec["zone-number"],
        false,
        cdec,
      );

      setCustomerCameras({ withinTheZone, outOfZone });
    }
  }, [customerCamerasAndServers, cdec]);

  const handleStopVideoRef = useRef(handleStopVideo);
  useEffect(() => {
    handleStopVideoRef.current = handleStopVideo;
  }, [handleStopVideo]);

  useEffect(() => {
    if (!isTabVisible) return;

    const controller = new AbortController();
    const signal = controller.signal;

    setLiveStreamUrl("");

    const fetchData = async () => {
      try {
        const type = await fetchMainStream(
          selectedCamera.id,
          videoServerConfig.url,
          videoServerConfig.username,
          videoServerConfig.password,
          signal,
        );
        setSelectedContentType(type);

        const url = await getLiveStream(
          selectedCamera.id,
          videoServerConfig.url,
          videoServerConfig.username,
          videoServerConfig.password,
          signal,
        );
        setLiveStreamUrl(url ?? "");

        await getArchiveBoundaries(
          selectedCamera.id,
          videoServerConfig.url,
          videoServerConfig.username,
          videoServerConfig.password,
          setBoundaries,
          signal,
        );
        await getFrames(
          selectedCamera.id,
          videoServerConfig.url,
          videoServerConfig.username,
          videoServerConfig.password,
          setFrame,
          Date.parse(cdec.sent),
          signal,
        );
        await fetchArchivedSnapshot(
          selectedCamera.id,
          videoServerConfig.url,
          videoServerConfig.username,
          videoServerConfig.password,
          setSnapshotUrl,
          Date.parse(cdec.sent),
          signal,
        );

        setViewMode("archive-snapshot");
        setArchivedVideoCurrentTime(0);
        setArchivedVideo(undefined);
      } catch (error) {
        if (error instanceof Error) {
          if (error.name !== "AbortError") {
            console.error(error);
          }
        }
      }
    };

    fetchData();

    return () => {
      controller.abort();
      handleStopVideoRef.current?.();
    };
  }, [
    selectedCamera,
    cdec.sent,
    isTabVisible,
    videoServerConfig.url,
    videoServerConfig.username,
    videoServerConfig.password,
  ]);

  useEffect(() => setArchivedVideoCurrentTime(0), [viewMode]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () =>
      document.removeEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  const isArchiveValid = useCallback(() => {
    const frameTimeDifference = (a: number, b: number) => Math.abs(a - b);
    if (frame) {
      const prevFrameDiff = frameTimeDifference(
        Date.parse(cdec.sent),
        frame.prevFrameTime,
      );
      const nextFrameDiff = frameTimeDifference(
        Date.parse(cdec.sent),
        frame.nextFrameTime,
      );
      return (
        (prevFrameDiff < 10000 || nextFrameDiff < 10000) && boundaries !== null
      );
    }
    return false;
  }, [frame, boundaries, cdec.sent]);

  const toggleFullScreen = (type: "video" | "snapshot") =>
    setFullScreenState((prev) => ({ ...prev, [type]: !prev[type] }));

  const handleVisibilityChange = () =>
    setIsTabVisible(document.visibilityState === "visible");

  const mainContainerStyle: (isFullScreen: boolean) => React.CSSProperties = (
    isFullScreen: boolean,
  ) => ({
    width: isFullScreen ? "100vw" : "100%",
    height: isFullScreen ? "100vh" : "340px",
    position: isFullScreen ? "fixed" : "relative",
    top: 0,
    left: 0,
    objectFit: "fill",
    borderRadius: isFullScreen ? "0px" : "16px",
    zIndex: isFullScreen ? 9998 : 1,
    background: "#e5e5e5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  const getToggleStyle: (
    isFullScreen: boolean,
    otherFullScreen: boolean,
  ) => React.CSSProperties = (
    isFullScreen: boolean,
    otherFullScreen: boolean,
  ) => ({
    position: isFullScreen ? "fixed" : "absolute",
    bottom: "8px",
    right: "10px",
    zIndex: 9999,
    color: "#FFFFFF",
    cursor: "pointer",
    display: otherFullScreen ? "none" : "block",
  });

  const handleTimeChange = useCallback(
    (newValue) => {
      const controller = new AbortController();
      const signal = controller.signal;

      setTime(newValue);
      fetchArchivedSnapshot(
        selectedCamera.id,
        videoServerConfig.url,
        videoServerConfig.username,
        videoServerConfig.password,
        setSnapshotUrl,
        newValue,
        signal,
      );
    },
    [
      selectedCamera.id,
      videoServerConfig.password,
      videoServerConfig.url,
      videoServerConfig.username,
    ],
  );

  return (
    <>
      <DialogCloseButton
        onClick={() => {
          handleStopVideo();
          onClose();
        }}
      />
      <Box sx={{ minWidth: "1200px", p: 2, pt: 2 }}>
        <InfoPanel
          cdec={cdec}
          customerCameras={customerCameras.withinTheZone}
        />
        <SnapshotAndLiveStream
          viewMode={viewMode}
          setViewMode={setViewMode}
          isArchiveValid={() => isArchiveValid()}
          archivedVideo={archivedVideo}
          snapshotUrl={snapshotUrl}
          fullScreenState={fullScreenState}
          toggleFullScreen={toggleFullScreen}
          selectedContentType={selectedContentType}
          liveStreamUrl={liveStreamUrl}
          setLiveStreamUrl={setLiveStreamUrl}
          videoRef={videoRef}
          imgRef={imgRef}
          mainContainerStyle={mainContainerStyle}
          getToggleStyle={getToggleStyle}
          archiveVideoRef={archiveVideoRef}
          setArchivedVideoCurrentTime={setArchivedVideoCurrentTime}
          selectedCamera={selectedCamera}
          videoServerConfig={videoServerConfig}
          customerCamerasAndServers={customerCamerasAndServers?.cameras || []}
          cdec={cdec}
          setArchivedVideo={setArchivedVideo}
        />
        <SliderAndTab
          cdec={cdec}
          time={time}
          customerCamerasAndServers={customerCamerasAndServers?.cameras || []}
          viewMode={viewMode}
          handleTimeChange={handleTimeChange}
          isArchiveValid={() => isArchiveValid()}
          setSelectedCamera={setSelectedCamera}
          canvasRef={canvasRef}
          archiveVideoRef={archiveVideoRef}
          archivedVideo={archivedVideo}
          archivedVideoCurrentTime={archivedVideoCurrentTime}
          frame={frame}
          customerCameras={customerCameras}
          selectedCamera={selectedCamera}
        />
      </Box>
    </>
  );
};

export default CameraModal;

import { styled } from "@mui/material";

const SubForm = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginLeft: "6px",
  marginRight: "6px",
  marginTop: 0,
  width: "33.33%",
});

interface FormLayoutProps {
  firstChild: JSX.Element;
  secondChild?: JSX.Element;
  thirdChild?: JSX.Element;
}

const FormLayout = ({
  firstChild,
  secondChild,
  thirdChild,
}: FormLayoutProps) => {
  return (
    <>
      <SubForm sx={{ marginLeft: 0 }}>{firstChild}</SubForm>
      <SubForm>{secondChild}</SubForm>
      <SubForm sx={{ marginRight: 0 }}>{thirdChild}</SubForm>
    </>
  );
};

export default FormLayout;

import { Alert, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";

interface CustomAlertProps {
  sx?: SxProps<Theme>;
  text: ReactNode;
  alertType: "success" | "info" | "warning" | "error";
}

const CustomAlert = ({ sx, text, alertType }: CustomAlertProps) => (
  <Alert
    severity={alertType}
    sx={[{ alignItems: "center" }, ...(Array.isArray(sx) ? sx : [sx])]}
  >
    {text}
  </Alert>
);

export default CustomAlert;

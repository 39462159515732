import { ID } from "@/api";
import { Customer } from "@/api/alarm";
import { EnhancedTable } from "@mb-pro-ui/components/table";
import { useCreate, useEvents, useGetOne } from "@mb-pro-ui/utils";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  SvgIcon,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { Column } from "react-table";
import { ReactComponent as AlertReviewTasksIcon } from "../../icons/customer/AlertReviewTasks.svg";
import { ReactComponent as VideoIcon } from "../../icons/module/Video.svg";
import CdecModal, { ViewState } from "../operator/cdec/CdecModal";
import ManualEventButton from "../operator/cdec/header/ManualEventButton";
import { SnackbarState } from "../settings/types";
import { Snackbar } from "../settings/utils";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import LinkStyleText from "../utils/LinkStyleText";
import { useAcknowledge } from "./AcknowledgeContext";
import { ExtendedAlertReviewTask, groupTasksByCdecId } from "./SubtasksWidget";
import { Cdec, Intervention } from "./types";
import { useInterventionEvents } from "./useInterventionEvents";

export type ModalViewState = {
  view: ViewState;
  $version: number;
};

type IconState = "none" | "light" | "dark" | "red";

export const getDateText = (
  timestamp: string | null | undefined,
  { formatTime, formatDate }: Pick<IntlShape, "formatTime" | "formatDate">,
  includeSeconds = false,
  alwaysFullFormat = false,
) => {
  if (!timestamp) return timestamp;

  const isToday = moment(timestamp).isSame(moment(), "day");

  const timeFormat: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    ...(includeSeconds && { second: "2-digit" }),
  };
  const time = formatTime(timestamp, timeFormat);

  if (alwaysFullFormat || !isToday) {
    const dateFormat: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    let date = formatDate(timestamp, dateFormat);
    date = date.split(" ").join("");
    return `${date} ${time}`;
  }

  return time;
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.info.main,
    top: 20,
    right: -10,
  },
}));

export const EventsWidget = ({
  intervention,
  interventionAllowed,
  refetchIntervention,
  isOwn,
  sx = [],
}: {
  intervention?: Intervention;
  interventionAllowed: boolean;
  refetchIntervention: () => Promise<any>;
  isOwn?: boolean;
  sx?: SxProps<Theme>;
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [unackCdecs, setUnackCdecs] = useAcknowledge();
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [selectedCdec, setSelectedCdec] = useState<Cdec | null>(null);
  const [cdecModalOpen, setCdecModalOpen] = useState<boolean>(false);
  const [modalViewState, setModalViewState] = useState<ModalViewState>({
    view: "standard",
    $version: 1.0,
  });

  const isOpen = intervention?.["close-time"] === null;
  const startEvent = intervention?.["start-event"];

  const fields = {
    customers: ["cameras"],
  } as const;

  const include = {
    cameras: {},
  } as const;

  const queryResult = useGetOne<Customer>(
    "alarm/customers",
    intervention?.customer?.id as string,
    {
      fields,
      include,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: cdecs,
    refetch,
    status: queryStatus,
    remove,
  } = useInterventionEvents(intervention);

  const { mutate: create, isLoading } = useCreate("alarm/interventions", {
    onSuccess: () => {
      refetch();
      refetchIntervention();
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Acknowledged" }),
      });
    },
    onError: () => {
      setSnackbarState({
        message: formatMessage({ defaultMessage: "Unsuccessfull operation" }),
        error: true,
      });
    },
  });

  const firstCdec = cdecs?.[cdecs?.length - 1]?.id;
  const ackEvent = startEvent ?? firstCdec;

  const acknowledge = useCallback(() => {
    if (ackEvent) {
      create({ "start-event": ackEvent });
    }
  }, [ackEvent, create]);

  useEvents(["cdec-insert"], () => refetch());

  useEffect(() => {
    if (cdecs) {
      setUnackCdecs(cdecs.filter((cdec) => !cdec.intervention));
    }
  }, [cdecs, setUnackCdecs]);

  useEffect(() => {
    return () => {
      // cleanup shared state between EventsWidgets (EventsWidgets force re mounted with key prop)
      setUnackCdecs([]);
      remove();
    };
  }, [setUnackCdecs, remove]);

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const handleCdecModalOpen = (cdec: Cdec, view: ModalViewState) => {
    setSelectedCdec(cdec);
    setCdecModalOpen(true);
    setModalViewState(view);
  };

  const handleCdecModalClose = () => {
    setCdecModalOpen(false);
    setSelectedCdec(null);
  };

  const isUnacknowledgedEvent = (unackCdecs: { id: ID }[], id: ID): boolean => {
    return unackCdecs.some((cdec) => cdec.id === id);
  };

  const getIconState = useCallback(
    (tasks: ExtendedAlertReviewTask[]): IconState => {
      if (!tasks || tasks.length === 0) {
        return "none";
      }

      const hasAnyTrueResult = tasks.some((task) => task.result === true);
      if (hasAnyTrueResult) {
        return "red";
      }

      const hasReviewTasks = tasks.some((task) => task.result === null);
      if (hasReviewTasks) {
        return "light";
      }

      const allTasksCompleted = tasks.every((task) => task.result === false);
      if (allTasksCompleted) {
        return "dark";
      }

      return "none";
    },
    [],
  );

  const getTooltipMessage = useCallback(
    (iconState: IconState): string => {
      switch (iconState) {
        case "light":
          if (intervention?.phase === "review-alerts") {
            return formatMessage({
              defaultMessage: "Reviewable alarm",
            });
          } else {
            return formatMessage({
              defaultMessage: "Unreviewed alarm",
            });
          }
        case "dark":
          return formatMessage({
            defaultMessage: "False alarm",
          });
        case "red":
          return formatMessage({
            defaultMessage: "Real alarm",
          });
        default:
          return "";
      }
    },
    [formatMessage, intervention],
  );

  const columns = useMemo(
    (): Column<Cdec>[] => [
      {
        accessor: "arrived",
        Header: formatMessage({
          defaultMessage: "Arrival time",
          description: "Cdec felugró részletes nézet érkezés",
        }),
        Cell: ({ value, row }) => {
          const groupedTasks = groupTasksByCdecId(
            intervention?.[
              "alert-review-tasks"
            ] as unknown as ExtendedAlertReviewTask[],
          );

          const tasksForThisRow = groupedTasks[row.original.id] || [];
          const iconState = getIconState(tasksForThisRow);
          const tooltipMessage = getTooltipMessage(iconState);

          return (
            <Box
              component="span"
              sx={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              {iconState !== "none" && (
                <Tooltip title={tooltipMessage}>
                  <SvgIcon
                    viewBox="0 -2 20 20"
                    component={AlertReviewTasksIcon}
                    sx={{
                      color: (theme) => {
                        if (iconState === "dark") {
                          return theme.palette.grey["800"];
                        }

                        if (iconState === "red") {
                          return theme.palette.error.light;
                        }

                        return theme.palette.divider;
                      },
                      width: "20px",
                    }}
                  />
                </Tooltip>
              )}
              {isUnacknowledgedEvent(unackCdecs, row.original.id) && (
                <BootstrapTooltip
                  backgroundColor="info.light"
                  title={formatMessage({
                    defaultMessage: "Unacknowledged event",
                  })}
                >
                  <NewReleasesIcon color="info" fontSize="small" />
                </BootstrapTooltip>
              )}
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    {`${formatMessage(
                      {
                        defaultMessage: "Arrival time: {date}",
                      },
                      {
                        date: getDateText(value, intl, true),
                      },
                    )}\n${formatMessage(
                      {
                        defaultMessage: "Event time: {date}",
                        description:
                          "Intervention events widget arrived column tooltip info: sent date",
                      },
                      {
                        date: getDateText(row.original.sent, intl, true),
                      },
                    )}`}
                  </div>
                }
              >
                <span
                  style={{
                    marginLeft:
                      iconState === "none" &&
                      !isUnacknowledgedEvent(unackCdecs, row.original.id)
                        ? "20px"
                        : "0",
                  }}
                >
                  {getDateText(value, intl, true)}
                </span>
              </Tooltip>
            </Box>
          );
        },
        width: 125,
      },
      {
        id: "description",
        Header: formatMessage({
          defaultMessage: "Description",
          description: "Events widget localized description column label",
        }),
        accessor: "localized-description",
        width: 130,
        Cell: ({ value, row }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Tooltip
              title={<div style={{ whiteSpace: "pre-line" }}>{value}</div>}
            >
              <LinkStyleText
                color={row.original.color}
                onClick={() =>
                  handleCdecModalOpen(row.original, {
                    view: "standard",
                    $version: 1.0,
                  })
                }
              >
                {value}
              </LinkStyleText>
            </Tooltip>
            {queryResult.data?.cameras && (
              <Tooltip
                title={formatMessage({ defaultMessage: "View live stream" })}
              >
                <SvgIcon
                  viewBox="0 -1 27 27"
                  sx={{ cursor: "pointer", ml: 1 }}
                  onClick={() =>
                    handleCdecModalOpen(row.original, {
                      view: "camera",
                      $version: 2.0,
                    })
                  }
                  component={VideoIcon}
                />
              </Tooltip>
            )}
          </Box>
        ),
      },
    ],
    [
      formatMessage,
      intervention,
      getIconState,
      getTooltipMessage,
      unackCdecs,
      intl,
      queryResult.data?.cameras,
    ],
  );

  const ackButton = (
    <Acknowledge
      intervention={intervention}
      isLoading={isLoading}
      unackCdecs={unackCdecs}
      acknowledge={acknowledge}
    />
  );

  const manualButton = (
    <ManualEventButton account={intervention?.customer?.account} />
  );

  return (
    <>
      <Box
        sx={[
          {
            display: "flex",
            overflow: "auto",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <EnhancedTable
          columns={columns}
          data={cdecs ?? []}
          disableGlobalFilter
          prefix={
            <Typography color="primary.contrastText">
              {formatMessage({ defaultMessage: "Events" })}
            </Typography>
          }
          queryStatus={queryStatus}
          postfix={
            isOpen ? (
              isOwn && (
                <>
                  {manualButton}
                  {unackCdecs.length > 0 && interventionAllowed && ackButton}
                </>
              )
            ) : (
              <Typography sx={{ color: "primary.contrastText" }}>
                {formatMessage({ defaultMessage: "Closed intervention" })}
              </Typography>
            )
          }
          sx={[
            {
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              "& .MuiTableHead-root .MuiTableRow-root .MuiBox-root.css-1aim0fm .MuiTypography-root":
                {
                  pl: 1,
                },
              "& .MuiTableRow-root > .MuiTableCell-root:first-of-type .MuiTypography-root":
                {
                  pl: 1,
                },
              "& .MuiTableRow-root > .MuiTableCell-root:last-of-type .MuiTypography-root":
                {
                  pr: 1,
                },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        />
      </Box>
      <Snackbar
        onClose={snackbarOnClose}
        state={snackbarState}
        successColor="info.main"
      />
      {selectedCdec?.id && cdecModalOpen && (
        <CdecModal
          viewState={modalViewState}
          intervention={intervention}
          isUnacknowledgedEvent={isUnacknowledgedEvent(
            unackCdecs,
            selectedCdec?.id,
          )}
          cdecID={selectedCdec.id}
          alertReview={{ isAlertReview: true }}
          onClose={handleCdecModalClose}
        />
      )}
    </>
  );
};

type AcknowledgeProps = {
  intervention: Intervention | undefined;
  isLoading: boolean;
  acknowledge: () => void;
  unackCdecs: { id: ID }[];
};

const Acknowledge = ({
  intervention,
  isLoading,
  acknowledge,
  unackCdecs,
}: AcknowledgeProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ position: "relative", paddingRight: "10px" }}>
        <StyledBadge
          badgeContent={unackCdecs.length}
          color="info"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <BootstrapTooltip
            backgroundColor={theme.palette.info.main}
            title={formatMessage({
              defaultMessage: "Acknowledge events",
            })}
          >
            <IconButton
              disabled={
                !intervention?.["start-event"] ||
                isLoading ||
                unackCdecs.length === 0
              }
              onClick={() => {
                acknowledge();
              }}
            >
              <Box
                sx={{
                  backgroundColor: "info.main",
                  width: "5px",
                  height: "16px",
                  position: "absolute",
                }}
              />
              <NewReleasesIcon
                sx={{
                  color: "white",
                  borderRadius: "50%",
                  zIndex: 1,
                }}
              />
            </IconButton>
          </BootstrapTooltip>
        </StyledBadge>
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: "common.white",
              position: "absolute",
              top: "50%",
              left: "40%",
              marginTop: -1.5,
              marginLeft: -1.5,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

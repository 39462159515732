import DigestClient from "digest-fetch";

export interface ChannelData {
  servers: Server[];
  channels: Channel[];
  resources: any[];
  relations: any[];
}

export interface Channel {
  id: number;
  guid: string;
  title: string;
  server: number;
  ptzCapabilities: number;
  ptzPresets: PtzPreset[];
  ptzTours: any[];
  bookmarks: Bookmarks;
  metadata: Metadata;
  archiveServers: number[];
  archiveServerStreams: ArchiveServerStream[];
}

export interface ArchiveServerStream {
  id: number;
  streams: number;
}

export interface Bookmarks {
  view: boolean;
  create: boolean;
}

export interface Metadata {
  push: boolean;
}

export interface PtzPreset {
  id: string;
  title: string;
}

export interface Server {
  id: number;
  ip: string;
  httpsIp: string;
  name: string;
}

export const getCameraChannelId = async () => {
  try {
    const response = await fetch(`http://localhost:8090/channels`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data = await response.json();

    return data || null;
  } catch (e) {
    console.error(e);
    return "n/a";
  }
};

export const getChannelData = async (
  serverUrl: string,
  username: string,
  password: string,
  setState: React.Dispatch<React.SetStateAction<ChannelData | undefined>>,
  abortController: AbortController,
) => {
  const df = new DigestClient(username, password);

  try {
    const response = (await df.fetch(`${serverUrl}/channels?health=0`, {
      method: "GET",
      headers: {
        Accept: "text/event-stream",
      },
      signal: abortController.signal,
    })) as Response;

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let buffer = "";
    let jsonString = "";
    let processingEvent = false;

    if (!reader) {
      throw new Error("Failed to get response body reader");
    }

    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");
        buffer = lines.pop() || "";

        for (const line of lines) {
          if (line.startsWith("event: added")) {
            processingEvent = true;
            jsonString = "";
          }

          if (processingEvent && line.startsWith("data: ")) {
            jsonString += line.substring(6).trim();

            try {
              const jsonData = JSON.parse(jsonString);
              setState(jsonData);
            } catch (jsonError) {
              console.error(jsonError);
            } finally {
              processingEvent = false;
            }
          }
        }
      }
    } catch (streamError) {
      throw new Error(
        `${streamError instanceof Error ? streamError.message : streamError}`,
      );
    }
  } catch (error) {
    console.error(error);
    setState(undefined);
  } finally {
    abortController.abort();
  }
};

export const getFrames = async (
  cameraId: number | undefined,
  serverUrl: string,
  username: string,
  password: string,
  setState: React.Dispatch<React.SetStateAction<Record<string, number> | null>>,
  time: number,
  abortSignal: AbortSignal,
) => {
  if (cameraId === undefined) return;

  if (serverUrl && username && password) {
    const urlPrev = `${serverUrl}/archive/${cameraId}/prevFrameTime?time=${time}`;
    const urlNext = `${serverUrl}/archive/${cameraId}/nextFrameTime?time=${time}`;
    const df = new DigestClient(username, password);

    try {
      const [responsePrev, responseNext] = await Promise.all([
        df.fetch(urlPrev, { method: "GET", signal: abortSignal }),
        df.fetch(urlNext, { method: "GET", signal: abortSignal }),
      ]);

      if (responsePrev.ok && responseNext.ok) {
        const dataPrev = await responsePrev.json();
        const dataNext = await responseNext.json();
        setState({ prevFrameTime: dataPrev, nextFrameTime: dataNext });
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export const getArchiveBoundaries = async (
  cameraId: number | undefined,
  serverUrl: string,
  username: string,
  password: string,
  setState: React.Dispatch<
    React.SetStateAction<{ from: number; to: number } | null>
  >,
  abortSignal: AbortSignal,
) => {
  if (cameraId === undefined) return;

  if (serverUrl && username && password) {
    const url = `${serverUrl}/archive/${cameraId}/boundaries`;
    const df = new DigestClient(username, password);

    try {
      const response = await df.fetch(url, {
        method: "GET",
        signal: abortSignal,
      });

      if (response.status === 500) {
        setState(null);
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setState(data);
      }
    } catch (error) {
      console.error(error);
      setState(null);
    }
  }
};

export const fetchArchivedVideo = async (
  cameraId: number | undefined,
  serverUrl: string,
  username: string,
  password: string,
  setState: React.Dispatch<
    React.SetStateAction<{ url: string; xStreamStartTime: number } | undefined>
  >,
  milliseconds: number,
): Promise<void> => {
  if (cameraId === undefined) return;

  if (serverUrl && username && password) {
    const url = `${serverUrl}/archive/${cameraId}/stream?time=${milliseconds}`;
    const df = new DigestClient(username, password);

    try {
      const response = (await df.fetch(url, { method: "GET" })) as Response;
      if (response.ok) {
        const xStreamStartTimeString = response.headers.get(
          "X-Stream-Start-Time",
        );

        if (xStreamStartTimeString !== null) {
          const xStreamStartTime = parseInt(xStreamStartTimeString);

          const video = await response.blob();

          setState({ url: URL.createObjectURL(video), xStreamStartTime });
        } else {
          setState(undefined);
        }
      }
    } catch (error) {
      console.error(error);
      setState(undefined);
      return;
    }
  }
};

export const fetchArchivedSnapshot = async (
  cameraId: number | undefined,
  serverUrl: string,
  username: string,
  password: string,
  setState: React.Dispatch<React.SetStateAction<string>>,
  time: number,
  abortSignal: AbortSignal,
): Promise<void> => {
  if (cameraId === undefined) return;

  if (serverUrl && username && password) {
    const boundaryUrl = `${serverUrl}/archive/${cameraId}/boundaries`;
    const snapshotUrl = `${serverUrl}/archive/${cameraId}/snapshot?time=${time}`;
    const df = new DigestClient(username, password);

    try {
      const boundaryResponse = await df.fetch(boundaryUrl, {
        method: "GET",
        signal: abortSignal,
      });

      if (boundaryResponse.status === 500) {
        setState("");
        return;
      }

      if (boundaryResponse.status === 404) {
        setState("");
        return;
      }

      const snapshotResponse = (await df.fetch(snapshotUrl, {
        method: "GET",
      })) as Response;

      if (snapshotResponse && snapshotResponse.ok) {
        const snapshot = await snapshotResponse.blob();
        setState(URL.createObjectURL(snapshot));
      }
    } catch (error) {
      console.error(error);
      setState("");
    }
  }
};

export const fetchMainStream = async (
  cameraId: number | undefined,
  serverUrl: string,
  username: string,
  password: string,
  abortSignal: AbortSignal,
  setState?: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
  if (cameraId && serverUrl && username && password) {
    let url = `${serverUrl}/live/${cameraId}/mainStream`;
    const df = new DigestClient(username, password);
    try {
      const response = await df.fetch(url, {
        method: "HEAD",
        signal: abortSignal,
      });
      if (response.ok) {
        const contentType = response.headers.get("content-type") ?? undefined;
        setState?.(contentType);
        return contentType;
      }
    } catch (error) {
      console.error(error);
      setState?.(undefined);
      return undefined;
    }
  }
};

export const getLiveStream = async (
  cameraId: number | undefined,
  serverUrl: string,
  username: string,
  password: string,
  abortSignal?: AbortSignal,
  setState?: React.Dispatch<React.SetStateAction<string>>,
) => {
  const date = new Date();
  const token = `${date.getTime()}-${crypto.randomUUID()}`;

  if (cameraId && serverUrl && username && password) {
    let url = `${serverUrl}/live/${cameraId}/addStreamToken?stream=mainStream&token=${token}`;
    const df = new DigestClient(username, password);
    try {
      const response = await df.fetch(url, {
        method: "GET",
        signal: abortSignal,
      });
      if (response.ok) {
        const liveStreamUrl = `${serverUrl}/retrieveLiveStreamByToken?token=${token}`;

        setState?.(liveStreamUrl);
        return liveStreamUrl;
      } else {
        console.error("Token beállítás sikertelen");
      }
    } catch (error) {
      console.error(error);
    }
  }
};

const CustomerCamerasSnapshot = () => {
  return <></>;
};

export default CustomerCamerasSnapshot;

import {
  EnhancedTable,
  EnhancedTableProps,
  Filter,
  FilterView,
  RemoteFilterViews,
} from "@mb-pro-ui/components/table";
import { JsonapiError } from "@mb-pro-ui/utils";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { UseQueryResult } from "react-query";
import { Row } from "react-table";
import LinkButton from "../../utils/LinkButton";
import { SnackbarState } from "../types";
import Snackbar from "./Snackbar";
import { ActionType } from "./types";

export interface RenderCreateFormProps {
  isFormOpen: boolean;
  onFormClose: () => void;
  refetch: () => void;
  setSnackbarState: (state: SnackbarState | undefined) => void;
}

export interface RenderUpdateFormProps extends RenderCreateFormProps {
  selectedId: string | undefined;
}

type StringValuedKey<T extends object> = {
  [K in keyof T & string]: T[K] extends string ? K : never;
}[keyof T & string] &
  string;

export type QueryResult<T> = Pick<
  UseQueryResult<T[], JsonapiError>,
  "status" | "refetch" | "data"
>;

interface AllEntitiesPageProps<
  T extends object,
  IDField extends StringValuedKey<T>,
> extends Omit<EnhancedTableProps<T, {}>, "data" | "filters"> {
  idField?: IDField;
  data?: T[];
  queryResult: QueryResult<T>;
  title: string;
  notDocumentTitle?: boolean;
  renderForm?: (props: RenderCreateFormProps) => ReactNode;
  renderUpdateForm?: (props: RenderUpdateFormProps) => ReactNode;
  actionType?: ActionType | null;
  tableFilters?: FilterView[];
  handleFilterChange?: (filters: Filter[]) => void;
  limit?: number;
  disableNew?: boolean;
  postfix_prefix?: ReactNode;
  postfix_postfix?: ReactNode;
  actionLabel?: ReactNode;
  actionIcon?: ReactNode;
  tooltipText?: string;
}

const linkAction = <T extends object, IDField extends StringValuedKey<T>>(
  item: T,
  url: string,
  idField: IDField,
  actionLabel?: ReactNode,
  actionIcon?: ReactNode,
) => (
  <LinkButton
    url={`${url}/${item[idField]}`}
    message={actionLabel ?? <FormattedMessage defaultMessage="Edit" />}
    icon={actionIcon}
  />
);

const AllEntities = <
  T extends object,
  IDField extends StringValuedKey<T> = StringValuedKey<T>,
>({
  idField = "id" as IDField,
  queryResult: { data: entities, status: queryStatus, refetch },
  data,
  title,
  notDocumentTitle,
  renderForm,
  renderUpdateForm,
  actionType = ActionType.LINK,
  tableFilters,
  handleFilterChange,
  limit,
  columns,
  disableNew,
  postfix_prefix,
  postfix_postfix,
  actionLabel,
  actionIcon,
  tooltipText,
  ...rest
}: AllEntitiesPageProps<T, IDField>) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();
  const [selectedId, setSelectedId] = useState<T[IDField]>();

  const action = useMemo(
    () =>
      actionType === ActionType.LINK
        ? ({ original }: Row<T>, url: string) =>
            linkAction(original, url, idField, actionLabel, actionIcon)
        : actionType === ActionType.POPUP
          ? ({ original }: Row<T>) => (
              <LinkButton
                message={actionLabel}
                icon={actionIcon}
                onClick={() => setSelectedId(original[idField])}
              />
            )
          : null,
    [actionType, idField, actionLabel, actionIcon],
  );

  useEffect(() => {
    if (title && !notDocumentTitle) {
      document.title = title + " - MonitoringBook Pro";
    }
  }, [title, notDocumentTitle]);

  return (
    <>
      <EnhancedTable
        data={data || entities || []}
        columns={columns}
        action={action ?? undefined}
        actionCellWidth={130}
        actionPosition="left"
        prefix={
          <Typography color="primary.contrastText" sx={{ marginRight: "20px" }}>
            {title}
          </Typography>
        }
        postfix={
          disableNew ? undefined : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {postfix_prefix}
              <Tooltip title={tooltipText}>
                <IconButton
                  sx={{
                    color: "primary.contrastText",
                  }}
                  onClick={() => setIsFormOpen(true)}
                  size="small"
                >
                  <AddOutlinedIcon />
                </IconButton>
              </Tooltip>
              {postfix_postfix}
            </Box>
          )
        }
        queryStatus={queryStatus}
        sx={{ height: "100%" }}
        emptyLineCount={1}
        limit={limit}
        {...rest}
        filters={
          tableFilters &&
          ((opts) => (
            <RemoteFilterViews
              {...opts}
              tableFilters={tableFilters}
              onFilterChange={handleFilterChange}
            />
          ))
        }
      />
      {renderForm &&
        renderForm({
          isFormOpen,
          onFormClose: () => setIsFormOpen(false),
          refetch,
          setSnackbarState,
        })}
      {renderUpdateForm &&
        renderUpdateForm({
          isFormOpen: !!selectedId,
          onFormClose: () => setSelectedId(undefined),
          refetch,
          setSnackbarState,
          selectedId: selectedId as string | undefined,
        })}
      <Snackbar
        onClose={() => setSnackbarState({ message: undefined })}
        state={snackbarState}
      />
    </>
  );
};

export default AllEntities;

import { Button } from "@mb-pro-ui/components";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  Dialog as MUIDialog,
  Typography,
  lighten,
  styled,
  useTheme,
} from "@mui/material";
import { isEqual, omit } from "lodash";
import get from "lodash/get";
import React, { ReactNode, useCallback, useMemo } from "react";
import { Form, FormRenderProps, useFormState } from "react-final-form";
import { useFieldArray } from "react-final-form-arrays";
import { useIntl } from "react-intl";
import { Route, useHistory, useRouteMatch } from "react-router";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import { StyledTooltip } from "../utils/StyledHeaderComponents";
import Widget from "../utils/Widget";

const classes = {
  active: `NavLink-active`,
};

const StyledNavLink = styled((props: NavLinkProps) => <NavLink {...props} />)(
  ({ theme }) => ({
    textDecoration: "none",
    color: "inherit",
    [`&.${classes.active}`]: {
      "& > div": {
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
        boxShadow: `3px 0px 0px ${theme.palette.primary.main} inset`,
      },
      "& > div > div": {
        color: theme.palette.primary.main,
      },
      "& > div:hover": {
        backgroundColor: lighten(theme.palette.primary.light, 0.7),
      },
    },
  }),
);

interface UrlParams {
  by: "new" | "byId" | "byNum";
  id: string;
  num: string;
}
interface DialogProps<T> {
  name: string;
  formDialogHeaderNew: string;
  formDialogHeaderEdit: string;
  formDialogRenderer: (
    state: FormRenderProps<T, any> & { isCreateMode: boolean },
  ) => JSX.Element;
  newItemInitialValues?: Partial<Omit<T, "id">>;
  url: string;
}

export const Dialog = <T,>({
  formDialogHeaderNew,
  formDialogHeaderEdit,
  formDialogRenderer,
  name,
  newItemInitialValues,
  url,
}: DialogProps<T>) => {
  const { replace, location } = useHistory();
  const { formatMessage } = useIntl();
  const {
    fields: { value, push, update },
  } = useFieldArray(name, { subscription: { value: true } });
  const {
    params: { by, num },
  } = useRouteMatch<UrlParams>();

  const closeDialog = useCallback(() => {
    replace(url);
  }, [replace, url]);

  const idx = useMemo(() => {
    if (by === "byNum") {
      return parseInt(num, 10);
    } else if (by === "byId") {
      return value.findIndex((f) => String(f.id) === String(num));
    } else {
      return -1;
    }
  }, [by, num, value]);

  const initialValues = useMemo(() => {
    if (idx !== -1) {
      return value[idx];
    } else {
      return undefined;
    }
  }, [value, idx]);

  const onSubmit = useCallback(
    (vals: T) => {
      if (initialValues) {
        if (idx !== -1) {
          update(idx, vals);
        }
      } else {
        push(vals);
      }
      closeDialog();
    },
    [closeDialog, push, update, initialValues, idx],
  );

  const isCreateMode = useMemo(
    () => location.pathname.split("/").includes("new"),
    [location.pathname],
  );

  return (
    <MUIDialog open onClose={closeDialog}>
      <DialogTitle>
        {initialValues ? formDialogHeaderEdit : formDialogHeaderNew}
      </DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues ? initialValues : newItemInitialValues}
      >
        {(state) => {
          const { handleSubmit, submitting, invalid, pristine } = state;

          const extendedState = {
            ...state,
            isCreateMode,
          };

          return (
            <>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {formDialogRenderer(extendedState)}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  type="button"
                  onClick={closeDialog}
                  disabled={submitting}
                >
                  {formatMessage({
                    defaultMessage: "Cancel",
                    description: "Card component with dialog, cancel button",
                  })}
                </Button>
                <Button
                  type="submit"
                  disabled={submitting || pristine || invalid}
                  onClick={handleSubmit}
                  sx={{ marginLeft: "32px" }}
                >
                  {formatMessage({
                    defaultMessage: "Confirm",
                    description: "Card component with dialog, confirm button",
                  })}
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Form>
    </MUIDialog>
  );
};

type FieldArrayListItemProps = {
  item: any;
  data: any[];
  index: number;
  labels?: { [key: string]: any };
  mainIndex: number;
  name: string;
  readOnly?: boolean;
  url: string;
  highLightDirty: boolean;
  itemRenderer?: (fieldValue: any) => JSX.Element;
  handleDelete: (index: number) => void;
  handleRestore: (index: number) => void;
  getField?: (
    item: any,
    key: string,
    defaultValue?: unknown,
  ) => React.ReactNode;
};

const FieldArrayListItem = ({
  data,
  index,
  mainIndex,
  labels,
  highLightDirty,
  item,
  name,
  readOnly,
  url,
  handleDelete,
  handleRestore,
  itemRenderer,
  getField,
}: FieldArrayListItemProps) => {
  return (
    <ListItem
      key={`${item}_${index}`}
      sx={[
        {
          color: (theme) =>
            name === "area-rules"
              ? item.allowed
                ? theme.palette.info.main
                : theme.palette.warning.main
              : undefined,
          display: "flex",
          backgroundColor: (theme) =>
            highLightDirty ? theme.palette.highlight.dirty : undefined,
          borderRadius: "10px",
          boxShadow: "0px 0px 1px 1px rgba(0,0,0,0.1)",
        },
      ]}
    >
      <StyledNavLink
        to={readOnly ? "#" : `${url}/${name}/byNum/${index}`}
        sx={readOnly ? { cursor: "auto" } : { flex: 1 }}
      >
        {itemRenderer && itemRenderer(data[index])}
        {labels
          ? Object.keys(labels ?? {}).map((e, j) => (
              <React.Fragment key={`${name}-array-${mainIndex ?? index}.${j}`}>
                <Typography
                  sx={{
                    color: "primary.light",
                    fontSize: "0.8rem",
                  }}
                >
                  {labels[e] ?? ""}
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "2%",
                  }}
                >
                  {getField && getField(data[mainIndex ?? index], e, "-")}
                </Typography>
              </React.Fragment>
            ))
          : null}
      </StyledNavLink>
      <Box>
        {!data[index].$deleted ? (
          <IconButton
            size="large"
            sx={{
              padding: 0,
            }}
            onClick={() => {
              handleDelete(mainIndex);
            }}
          >
            <DeleteIcon
              sx={{
                color: "error.main",
              }}
            />
          </IconButton>
        ) : (
          <IconButton
            size="large"
            sx={{ padding: 0 }}
            onClick={() => {
              handleRestore(mainIndex);
            }}
          >
            <ReplayIcon sx={{ color: "warning.main" }} />
          </IconButton>
        )}
      </Box>
    </ListItem>
  );
};

interface FieldArrayProps<T extends { id?: string }> {
  name: string;
  label: ReactNode;
  formDialogHeaderNew: string;
  formDialogHeaderEdit: string;
  placeholderMessage?: string;
  itemRenderer?: (fieldValue: T) => JSX.Element;
  labels?: { [key: string]: any };
  formDialogRenderer: (
    state: FormRenderProps<T, any> & { isCreateMode: boolean },
  ) => JSX.Element;
  initialValues?: Partial<Omit<T, "id">>;
  hasAdd?: boolean;
  readOnly?: boolean;
  getField?: (item: T, key: string, defaultValue?: unknown) => React.ReactNode;
}

const FieldArray = <T extends { id?: string }>({
  name,
  label,
  labels,
  formDialogHeaderNew,
  formDialogHeaderEdit,
  placeholderMessage,
  itemRenderer,
  formDialogRenderer,
  initialValues,
  hasAdd = true,
  readOnly,
  getField = get,
}: FieldArrayProps<T>) => {
  const { formatMessage } = useIntl();

  const { path, url } = useRouteMatch();

  const { palette } = useTheme();

  const {
    fields: { value: fieldValue, update: fieldUpdate, remove: fieldRemove },
  } = useFieldArray(name);

  const { initialValues: initialFormValues } = useFormState({
    subscription: { initialValues: true },
  });

  const handleDelete = (index: number) => {
    if (fieldValue[index].id) {
      fieldUpdate(index, { ...fieldValue[index], $deleted: true });
    } else {
      fieldRemove(index);
    }
  };

  const handleRestore = (index: number) => {
    const restoreItem = fieldValue[index];
    if (restoreItem.id) {
      fieldUpdate(index, omit(restoreItem, ["$deleted"]));
    }
  };

  const [allowedRules, disallowedRules] = useMemo(() => {
    if (name !== "area-rules") {
      return [undefined, undefined];
    } else if (fieldValue && name === "area-rules") {
      const allowed = fieldValue.filter((rule) => rule.allowed === true);
      const disallowed = fieldValue.filter((rule) => rule.allowed !== true);
      return [allowed, disallowed];
    } else {
      return [undefined, undefined];
    }
  }, [name, fieldValue]);

  return (
    <>
      <Route path={`${path}/${name}/:by/:num`}>
        <Dialog
          name={name}
          formDialogRenderer={formDialogRenderer}
          newItemInitialValues={initialValues}
          formDialogHeaderNew={formDialogHeaderNew}
          formDialogHeaderEdit={formDialogHeaderEdit}
          url={url}
        />
      </Route>
      <Widget
        placeholderMessage={
          placeholderMessage
            ? placeholderMessage
            : formatMessage({
                defaultMessage: "No items",
                description: "FieldArray placeholder",
              })
        }
        title={label}
        headerSx={{ height: "50px" }}
        postfix={
          <>
            {fieldValue?.length && fieldValue.length > 0 ? (
              <Box
                sx={{ display: "flex", flex: 1, padding: "0 12px 0 12px" }}
              >{`(${fieldValue.length})`}</Box>
            ) : null}
            <Box sx={{ display: "flex" }}>
              <Divider
                orientation="vertical"
                color={palette.primary.contrastText}
                variant="fullWidth"
                sx={{ margin: "0 12px 0 12px" }}
                flexItem
              />
              <StyledTooltip title={formatMessage({ defaultMessage: "Add" })}>
                <span>
                  {readOnly || !hasAdd ? null : (
                    <Link to={`${url}/${name}/new/0`}>
                      <IconButton
                        sx={{
                          color: "common.white",
                          padding: 0,
                        }}
                      >
                        <AddCircleOutlineIcon fontSize="medium" />
                      </IconButton>
                    </Link>
                  )}
                </span>
              </StyledTooltip>
            </Box>
          </>
        }
        sx={{ alignSelf: "flex-start", mb: 1 }}
      >
        <List
          sx={{
            "&.MuiList-root": {
              paddingTop: "0",
              paddingBottom: "0",
            },
          }}
        >
          {(allowedRules || disallowedRules) && (
            <Box>
              {allowedRules && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      padding: 1,
                    }}
                  >
                    {formatMessage(
                      {
                        defaultMessage:
                          "Allowed {location, plural, one {location} other {locations}}",
                      },
                      { location: allowedRules.length },
                    )}
                  </Typography>
                  {allowedRules.map((item, i) =>
                    FieldArrayListItem({
                      data: allowedRules,
                      item,
                      index: i,
                      mainIndex: fieldValue.findIndex(
                        (initItem) => initItem.id === item.id,
                      ),
                      name,
                      readOnly,
                      highLightDirty: initialFormValues[name]
                        ? !isEqual(
                            initialFormValues[name].find(
                              (initVal: T) => initVal.id === item.id,
                            ),
                            item,
                          )
                        : false,
                      url,
                      handleDelete,
                      handleRestore,
                      itemRenderer,
                    }),
                  )}
                </Box>
              )}
              {disallowedRules && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      padding: 1,
                    }}
                  >
                    {formatMessage(
                      {
                        defaultMessage:
                          "Forbidden {location, plural, one {location} other {locations}}",
                      },
                      {
                        location: disallowedRules.length,
                      },
                    )}
                  </Typography>
                  {disallowedRules.map((item, i) =>
                    FieldArrayListItem({
                      data: disallowedRules,
                      item,
                      index: i,
                      mainIndex: fieldValue.findIndex(
                        (initItem) => initItem.id === item.id,
                      ),
                      name,
                      readOnly,
                      highLightDirty: initialFormValues[name]
                        ? !isEqual(
                            initialFormValues[name].find(
                              (initVal: T) => initVal.id === item.id,
                            ),
                            item,
                          )
                        : false,
                      url,
                      handleDelete,
                      handleRestore,
                      itemRenderer,
                    }),
                  )}
                </Box>
              )}
            </Box>
          )}
          {!(allowedRules || disallowedRules) &&
            fieldValue &&
            fieldValue.map((item, i) =>
              FieldArrayListItem({
                data: fieldValue,
                item,
                index: i,
                mainIndex: i,
                name,
                readOnly,
                url,
                highLightDirty: initialFormValues[name]
                  ? !isEqual(
                      initialFormValues[name].find(
                        (initVal: T) => initVal.id === item.id,
                      ),
                      item,
                    )
                  : false,
                handleDelete,
                handleRestore,
                itemRenderer,
                getField,
                labels,
              }),
            )}
        </List>
      </Widget>
    </>
  );
};

export default FieldArray;

import { Event } from "@/api/alarm";
import { useGetAll } from "@mb-pro-ui/utils";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import identity from "lodash/identity";
import { useCallback, useMemo } from "react";
import { FormRenderProps, useFormState } from "react-final-form";
import { useIntl } from "react-intl";
import useTimestampFormat from "../../locales/useTimestampFormat";
import DatePickerFuturePresets from "../form/DatePickerFuturePresets";
import FieldArray from "../form/FieldArray";
import FormLayout from "../form/FormLayout";
import { StyledTextField } from "../form/StyledComponents";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import { CodeFilterInfo, FilteredEventsInfo } from "./EventDuplicate";
import { FormValues } from "./types";

export const InactiveCommentInfo = () => {
  const { formatMessage } = useIntl();
  return (
    <BootstrapTooltip
      title={
        <Typography variant="body2" color="primary">
          {formatMessage({
            defaultMessage:
              "The comment will be included in the deactivation event's description",
            description: "Event deactivation comment tooltip",
          })}
        </Typography>
      }
      backgroundColor="#fff"
    >
      <InfoIcon fontSize="small" />
    </BootstrapTooltip>
  );
};

export const TempInactiveInfo = () => {
  const { formatMessage } = useIntl();
  return (
    <BootstrapTooltip
      title={
        <Typography variant="body2" color="primary">
          {formatMessage({
            defaultMessage:
              "This event deactivation config will be deleted automatically at the specified time",
            description: "Event temporary deactivation tooltip",
          })}
        </Typography>
      }
      backgroundColor="#fff"
    >
      <InfoIcon fontSize="small" />
    </BootstrapTooltip>
  );
};

const DisabledEvent = ({
  isCreateMode,
  codeTableID,
  values: { "event-code": eventCode },
}: FormRenderProps<any, any> & {
  isCreateMode: boolean;
  codeTableID?: string;
}) => {
  const { formatMessage } = useIntl();
  const { dirtyFields } = useFormState({
    subscription: { dirty: true, dirtyFields: true },
  });

  const { data: filteredEvents, isLoading: areFilteredEventsLoading } =
    useGetAll<Event>("alarm/events", {
      filter: {
        "code-table": { eq: codeTableID },
        code: { similar: eventCode },
      },
      enabled: !!codeTableID,
    });

  // const { } = useFormState<FormValues>();

  return (
    <Box display="flex" flexDirection="column" minWidth="450px">
      <StyledTextField
        name="event-code"
        label={
          <>
            {formatMessage({ defaultMessage: "Event code filter" })}{" "}
            <CodeFilterInfo />
          </>
        }
        parse={identity}
        required
        InputProps={{
          endAdornment: (
            <FilteredEventsInfo
              events={filteredEvents}
              isLoading={areFilteredEventsLoading}
            />
          ),
        }}
        highlightDirty={!isCreateMode}
      />
      <StyledTextField
        name="zone"
        label={
          <>
            {formatMessage({ defaultMessage: "Zone filter" })}{" "}
            <CodeFilterInfo />
          </>
        }
        parse={identity}
        highlightDirty={!isCreateMode}
      />
      <StyledTextField
        name="partition"
        label={
          <>
            {formatMessage({ defaultMessage: "Partition filter" })}{" "}
            <CodeFilterInfo />
          </>
        }
        parse={identity}
        highlightDirty={!isCreateMode}
      />
      <DatePickerFuturePresets
        name="temp-inactive-ts"
        label={
          <>
            {formatMessage({
              defaultMessage: "Temporary deactivation",
              description: "Event temporary deactivation",
            })}{" "}
            <TempInactiveInfo />
          </>
        }
        highlightDirty={!isCreateMode}
      />
      {dirtyFields["event-code"] ||
      dirtyFields["zone"] ||
      dirtyFields["partition"] ||
      dirtyFields["temp-inactive-ts"] ? (
        <StyledTextField
          name="inactive-comment"
          label={
            <>
              {formatMessage({
                defaultMessage: "Comment on the deactivation",
              })}{" "}
              <InactiveCommentInfo />
            </>
          }
          highlightDirty={!isCreateMode}
        />
      ) : null}
    </Box>
  );
};

interface DisabledEventFormProps {
  readOnly?: boolean;
}

const DisabledEventForm = ({ readOnly }: DisabledEventFormProps) => {
  const { formatMessage } = useIntl();

  const labels = useMemo(
    () => ({
      "event-code": formatMessage({
        defaultMessage: "Event code filter",
      }),
      zone: formatMessage({
        defaultMessage: "Zone filter",
      }),
      partition: formatMessage({
        defaultMessage: "Partition filter",
      }),
      "temp-inactive-ts": formatMessage({
        defaultMessage: "Temporary deactivation",
        description: "Event temporary deactivation",
      }),
    }),
    [formatMessage],
  );

  const { formatTimestamp } = useTimestampFormat("full");

  const getField = useCallback(
    (item: any, key: string, defaultValue?: any) => {
      if (key === "zone" || key === "partition") {
        return String((item[key] || defaultValue) ?? "");
      }
      if (key === "temp-inactive-ts") {
        const ts = item[key];
        if (!ts) {
          return defaultValue ?? "";
        }
        return formatTimestamp(ts);
      }
      return String(item[key] ?? defaultValue ?? "");
    },
    [formatTimestamp],
  );

  const {
    values: { "code-table": codeTable },
  } = useFormState<FormValues>();

  return (
    <FormLayout
      firstChild={
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr" }}>
          <FieldArray
            name="disabled-events"
            label={formatMessage({
              defaultMessage: "Disabled events",
            })}
            formDialogHeaderNew={formatMessage({
              defaultMessage: "New event deactivation",
            })}
            formDialogHeaderEdit={formatMessage({
              defaultMessage: "Edit event deactivation",
            })}
            labels={labels}
            formDialogRenderer={(props) => (
              <DisabledEvent {...props} codeTableID={codeTable?.id} />
            )}
            readOnly={readOnly}
            getField={getField}
          />
        </Box>
      }
    />
  );
};

export default DisabledEventForm;

import {
  IntlErrorCode,
  MessageDescriptor,
  MissingTranslationError,
} from "@formatjs/intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/hu";
import "moment/locale/ro";
import "moment/locale/sk";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IntlProvider } from "react-intl";
import useMe from "../hooks/useMe";
import en_messages from "./en.json";
import hu_messages from "./hu.json";
import ro_messages from "./ro.json";
import sk_messages from "./sk.json";

const defaultLocale = "hu";

const localizedMessages = {
  hu: hu_messages,
  en: en_messages,
  sk: sk_messages,
  ro: ro_messages,
};

export type LocaleName = keyof typeof localizedMessages;
const locales = Object.keys(localizedMessages) as LocaleName[];

function mapLocale(locale: unknown): LocaleName {
  if (typeof locale !== "string") {
    return defaultLocale;
  }

  const validLocales = Object.keys(localizedMessages);
  if (validLocales.includes(locale)) {
    return locale as LocaleName;
  }

  const splitLocale = locale.split("-")[0].toLocaleLowerCase();
  if (validLocales.includes(splitLocale)) {
    return splitLocale as LocaleName;
  }

  return defaultLocale;
}

export interface Locale {
  locale: LocaleName;
  locales: LocaleName[];
  setLocale: (locale?: string) => void;
}

const LocaleContext = createContext<Locale | undefined>(undefined);

export const useLocale = () => {
  const value = useContext(LocaleContext);
  if (value === undefined) {
    throw new Error("useLocale must be used inside LocaleProvider");
  }
  return value;
};

function checkMissingTranslations() {
  for (const [locale, messages] of Object.entries(localizedMessages)) {
    // if (locale === "sk") {
    //   continue;
    // }

    const not_translated_prefix = `[${locale}]: `;
    for (const [id, str] of Object.entries(messages)) {
      if (str.startsWith(not_translated_prefix)) {
        console.log(`Missing translation: [${locale}] id=[${id}]: ${str}`);
      }
    }
  }
}

function printTranslationSuggestion(d: MessageDescriptor): any {
  const msg =
    typeof d.defaultMessage === "string"
      ? d.defaultMessage
      : (d.defaultMessage as any[])
          .map((m) => {
            if (m.value) {
              return m.type === 0 ? m.value : "{" + m.value + "}";
            }
            return "";
          })
          .join("");
  return '"' + d.id + '": "' + msg + '",';
}

function onLocaleError(err: any) {
  if (err.code === IntlErrorCode.MISSING_TRANSLATION) {
    const e = err as MissingTranslationError;
    if (e.descriptor) {
      console.log(
        "Missing translation: ",
        printTranslationSuggestion(e.descriptor),
      );
      return;
    }
  }
  console.error(err);
}

export default function LocaleProvider({
  connected,
  children,
}: PropsWithChildren<{ connected?: boolean }>) {
  const [_locale, _setLocale] = useState(() => {
    const storedLocale = localStorage.getItem("lang");

    if (storedLocale && mapLocale(storedLocale)) {
      return storedLocale;
    }

    const browserLocale = navigator.language.split("-")[0];

    if (mapLocale(browserLocale)) {
      localStorage.setItem("lang", browserLocale);
      return browserLocale;
    }

    localStorage.setItem("lang", defaultLocale);
    return defaultLocale;
  });

  const locale = mapLocale(_locale);

  const me = useMe(!connected);
  const refetchMe = me?.refetch;
  const uiLanguage = me?.data?.["ui-language"];

  const setLocale = useCallback(
    (locale) => {
      if (connected) {
        if (locale) {
          console.warn("Cannot setLocale with connected LocaleProvider");
        }
        refetchMe?.();
      } else {
        _setLocale(mapLocale(locale));
      }
    },
    [_setLocale, connected, refetchMe],
  );

  const value = useMemo(() => {
    checkMissingTranslations();
    return { locale, setLocale, locales };
  }, [locale, setLocale]);

  useEffect(() => {
    if (!connected || !uiLanguage) {
      return;
    }

    if (locale !== mapLocale(uiLanguage)) {
      _setLocale(mapLocale(uiLanguage));
    }
  }, [connected, uiLanguage, locale, _setLocale]);

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider
        locale={locale}
        messages={localizedMessages[locale]}
        defaultLocale={defaultLocale}
        onError={onLocaleError}
      >
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={locale}
        >
          {children}
        </LocalizationProvider>
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

import { Button } from "@mb-pro-ui/components";
import { TextField } from "@mb-pro-ui/components/form";
import { isHttpError, useApi } from "@mb-pro-ui/utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-query";
import { getErrorMessage } from "../../../../hooks/useErrorHandler";
import { SnackbarState } from "../../../settings/types";
import { Snackbar } from "../../../settings/utils";
import CustomerSelect from "../../../utils/CustomerSelect";

const ManualEventModalWrapper = ({
  open,
  onClose,
  account,
}: {
  open?: boolean;
  onClose: () => void;
  account?: string;
}) => {
  const [snackbarState, setSnackbarState] = useState<SnackbarState>();

  return (
    <>
      {/* Only render the modal if it's open, to avoid unnecessarily rendering a somewhat heavy component */}
      {open && (
        <ManualEventModal
          onClose={onClose}
          account={account}
          setSnackbarState={setSnackbarState}
        />
      )}
      {/* The snackbar is always rendered, to ensure the user sees the result of the manual event registration after closing the modal */}
      <Snackbar
        onClose={() => setSnackbarState(undefined)}
        state={snackbarState}
      />
    </>
  );
};

type FormValues = {
  customer: { account: string };
  message: string;
};

const ManualEventModal = ({
  onClose,
  account,
  setSnackbarState,
}: {
  onClose(): void;
  account?: string;
  setSnackbarState: (state: SnackbarState) => void;
}) => {
  const { formatMessage } = useIntl();
  const api = useApi();

  const { mutateAsync } = useMutation(
    (manualEventData: { customer: string; message: string }) =>
      api("/actions/alarm/manual-event", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(manualEventData),
      }),
  );

  // Async onSubmit to ensure the `submitting` form state is correctly set
  const onSubmit = async (values: FormValues) => {
    try {
      await mutateAsync({
        customer: account ?? values.customer.account,
        message: values.message,
      });
      onClose();
      setSnackbarState({
        message: formatMessage({
          defaultMessage: "Event successfully sent",
          description: "Kézi eseményküldés sikeres snackbar üzenet",
        }),
      });
    } catch (err) {
      setSnackbarState({
        message: isHttpError(err)
          ? getErrorMessage(err)
          : formatMessage({
              defaultMessage: "Failed to send event",
              description: "Kézi eseményküldés sikertelen snackbar üzenet",
            }),
        error: true,
      });
    }
  };

  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      subscription={{ valid: true, submitting: true }}
    >
      {({ valid, submitting, handleSubmit }) => (
        <Dialog
          component="form" // Making the dialog the `form` allows us to use form controls in both the `DialogContent` and the `DialogActions`
          onSubmit={handleSubmit}
          open
          onClose={onClose}
          disableScrollLock
        >
          <DialogTitle>
            <FormattedMessage defaultMessage="Manual event registration" />
          </DialogTitle>
          <DialogContent
            sx={{ paddingTop: 3, width: "400px", paddingBottom: 0 }}
          >
            {!account && (
              <Field name="customer">
                {({ input }) => (
                  <CustomerSelect
                    value={input.value === "" ? null : input.value}
                    setValue={input.onChange}
                    autoFocus
                    label={formatMessage({
                      defaultMessage: "Customer identifier",
                      description: "Kézi eseményküldés ügyfél azonosító",
                    })}
                  />
                )}
              </Field>
            )}
            <TextField
              name="message"
              label={formatMessage({
                defaultMessage: "Event message",
                description: "Kézi esemény szövege",
              })}
              autoFocus={!!account}
              fullWidth
              required
              margin="dense"
              InputLabelProps={{ shrink: true }}
              helperText={"\u00A0"} // Non-breaking space to ensure the field is always the same height
              highlightDirty={false}
            />
          </DialogContent>
          <DialogActions>
            <Button mode="secondary" onClick={onClose} disabled={submitting}>
              <FormattedMessage
                defaultMessage="Cancel"
                description="Manual event send cancel button"
              />
            </Button>
            <Button type="submit" disabled={!valid || submitting}>
              <FormattedMessage
                defaultMessage="Send"
                description="Kézi eseményküldés küldés gomb"
              />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  );
};

export default ManualEventModalWrapper;

import { ID } from "@/api";
import { Button } from "@mb-pro-ui/components";
import {
  JsonapiError,
  useCreate,
  useEvents,
  useUpdate,
} from "@mb-pro-ui/utils";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useAcknowledge } from "./AcknowledgeContext";
import { Intervention } from "./types";

type InterventionCloseDialogProps = {
  open: boolean;
  onClose: () => void;
  id: ID;
  handleQueryError: (error: JsonapiError) => void;
  intervention: Intervention | undefined;
  refetch: () => void;
};

const InterventionCloseDialog = ({
  open,
  onClose,
  id,
  handleQueryError,
  intervention,
  refetch,
}: InterventionCloseDialogProps) => {
  const history = useHistory();
  const [unackCdecs] = useAcknowledge();

  const { mutate: update, isLoading: isClosing } = useUpdate(
    "alarm/interventions",
    {
      onSuccess: () => {
        history.push(`/alarm/operator`);
      },
      onError: (error) => {
        handleQueryError(error);
        onClose();
      },
    },
  );

  const { mutate: create, isLoading: isAcknowledging } = useCreate(
    "alarm/interventions",
    {
      onSuccess: () => {
        handleInterventionCloseClick();
      },
      onError: (error) => {
        handleQueryError(error);
      },
    },
  );

  const startEvent = intervention?.["start-event"];

  const acknowledge = useCallback(() => {
    if (startEvent) {
      create({ "start-event": startEvent });
    }
  }, [startEvent, create]);

  const handleAcknowledgeAndCloseClick = () => {
    acknowledge();
  };

  useEvents(["cdec-insert"], () => refetch());

  const handleInterventionCloseClick = () => {
    update({ id: id, "close-time": "now", storno: false });
  };

  const handleDenyClick = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormattedMessage
          defaultMessage="Close Intervention"
          description="Close intervention popup title"
        />
      </DialogTitle>
      <DialogContent>
        {unackCdecs.length > 0 && (
          <Box>
            <Typography sx={{ color: "info.main" }}>
              <FormattedMessage
                defaultMessage={
                  "Attention! Customers intervention contains unacknowledged events!"
                }
              />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage
                defaultMessage="When <span>Close</span> the intervention will contain only the acknowledged events"
                values={{
                  span: (chunks: string) => (
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "0.6rem",
                        lineHeight: "1.5",
                        padding: "2px 5px",
                        width: "50px",
                        minWidth: "50px",
                        fontWeight: "initial",
                        color: "white",
                        backgroundColor: (theme) => theme.palette.warning.main,
                        borderRadius: 1,
                      }}
                    >
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Box>
        )}
        <DialogContentText>
          <FormattedMessage
            defaultMessage="Close {customer} customer's intervention ({id})"
            description="Close intervention poup message"
            values={{
              customer: `${intervention?.customer?.account} - ${intervention?.customer?.name}`,
              id: `${intervention?.id}`,
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {unackCdecs.length > 0 && (
          <Box sx={{ position: "relative" }}>
            <Button
              onClick={handleAcknowledgeAndCloseClick}
              mode="info"
              disabled={isAcknowledging || isClosing}
            >
              <FormattedMessage defaultMessage="Acknowledge and Close" />
            </Button>
            {(isAcknowledging || isClosing) && (
              <CircularProgress
                color="info"
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -1.5,
                  marginLeft: -1.5,
                }}
              />
            )}
          </Box>
        )}
        <Box sx={{ position: "relative" }}>
          <Button onClick={handleInterventionCloseClick} mode="warning">
            <FormattedMessage
              defaultMessage="Close"
              description="Close intervention popup acknowledge button"
            />
          </Button>
          {isClosing && (
            <CircularProgress
              color="warning"
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -1.5,
                marginLeft: -1.5,
              }}
            />
          )}
        </Box>
        <Button onClick={handleDenyClick} mode="secondary">
          <FormattedMessage
            defaultMessage="Cancel"
            description="Close intervention popup deny button"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InterventionCloseDialog;

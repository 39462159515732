import { WithFields, WithIncludes } from "@/api";
import { Intervention as _Intervention } from "@/api/alarm/interventions";
import { useGetAll } from "@mb-pro-ui/utils";
import { Cdec, categoriesFields, cdecFields, cdecIncludes } from "./types";

const fields = {
  "interventions-all": ["start-event", "customer", "close-time"],
  customers: ["id"],
} as const;

const includes = {
  customer: {},
} as const;

type Intervention = WithIncludes<
  WithFields<_Intervention, typeof fields>,
  typeof includes
>;

export const useInterventionEvents = (
  intervention?: Intervention | null,
  sort?: string[],
) => {
  const startEventID = intervention?.["start-event"];
  const customerID = intervention?.customer?.id;
  const isClosed = !!intervention?.["close-time"];

  const shouldUseCdec = !!startEventID && !!customerID && !isClosed;
  const shouldUseCdecAll = isClosed;

  const type = shouldUseCdec ? "cdec" : "cdec-all";

  return useGetAll<Cdec>(`alarm/${type}`, {
    enabled: shouldUseCdec || shouldUseCdecAll,
    filter: shouldUseCdec
      ? {
          customer: { eq: customerID },
          id: { gte: startEventID },
        }
      : {
          intervention: { eq: intervention?.id },
        },
    fields: { [type]: cdecFields, categories: categoriesFields },
    include: cdecIncludes,
    page: { limit: 10000 },
    refetchInterval: 10000,
    refetchIntervalInBackground: true,
    sort: sort ?? ["-id"],
  });
};

import { Header, MainMenu, SettingsMenu } from "@mb-pro-ui/components";
import { useAuth } from "@mb-pro-ui/utils";
import { Box, typographyClasses } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import { Route, Switch, useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { useAlarmMenuLinks, useUserSettingsMenuLinks } from "../../hooks";
import useMe from "../../hooks/useMe";
import logo from "../../icons/logo.svg";
import useAccessControlMenuLinks from "../access-control/useAccessControlMenuLinks";
import { StopAllAlertsButton, useAudio } from "../audio";
import VolumeButton from "../audio/VolumeButton";
import useFiresignalMenuLinks from "../firesignal/useFiresignalMenuLinks";
import useLampMenuLinks from "../lamp/useLampMenuLinks";
import useMeasMenuLinks from "../measurements/useMeasMenuLinks";
import usePatrolMenuLinks from "../patrol/usePatrolMenuLinks";
import useSmartAlarmMenuLinks from "../smartalarm/useSmartAlarmMenuLinks";
import {
  AccessControlRoutes,
  DispatcherRoutes,
  FiresignalRoutes,
  LampRoutes,
  MeasRoutes,
  ModuleID,
  PatrolRoutes,
  SmartAlarmRoutes,
  UserSettingsRoutes,
  VehicleMonitoringRoutes,
} from "../types";
import useVehicleMonitoringMenuLinks from "../vehiclemonitoring/useVehicleMonitoringMenuLinks";
import { ModuleMenu } from "./ModuleMenu";

const AlarmHeader = () => {
  const links = useAlarmMenuLinks();
  return (
    <>
      <MainMenu
        links={links}
        showLabels
        routes={Object.values(DispatcherRoutes)}
      />
      <ModuleMenu currentModuleID={ModuleID.Dispatcher} />
    </>
  );
};

const UserSettingsHeader = () => {
  const userlinks = useUserSettingsMenuLinks();
  return (
    <>
      <MainMenu links={userlinks} routes={Object.values(UserSettingsRoutes)} />
      <ModuleMenu />
    </>
  );
};

const FiresignalHeader = () => {
  const links = useFiresignalMenuLinks();
  return (
    <>
      <MainMenu links={links} routes={Object.values(FiresignalRoutes)} />
      <ModuleMenu currentModuleID={ModuleID.Firesignal} />
    </>
  );
};

const SmartAlarmHeader = () => {
  const links = useSmartAlarmMenuLinks();
  return (
    <>
      <MainMenu links={links} routes={Object.values(SmartAlarmRoutes)} />
      <ModuleMenu currentModuleID={ModuleID.SmartAlarm} />
    </>
  );
};

const MeasurementsHeader = () => {
  const links = useMeasMenuLinks();
  return (
    <>
      <MainMenu links={links} routes={Object.values(MeasRoutes)} />
      <ModuleMenu currentModuleID={ModuleID.Measurements} />
    </>
  );
};

const PatrolHeader = () => {
  const links = usePatrolMenuLinks();
  return (
    <>
      <MainMenu links={links} routes={Object.values(PatrolRoutes)} />
      <ModuleMenu currentModuleID={ModuleID.Patrol} />
    </>
  );
};

const LampHeader = () => {
  const links = useLampMenuLinks();
  return (
    <>
      <MainMenu
        links={links}
        routes={[
          ...Object.keys(LampRoutes).map(
            (key) => LampRoutes[key as keyof typeof LampRoutes],
          ),
        ]}
      />
      <ModuleMenu currentModuleID={ModuleID.Lamp} />
    </>
  );
};

const VehicleMonitoringHeader = () => {
  const links = useVehicleMonitoringMenuLinks();
  return (
    <>
      <MainMenu links={links} routes={Object.values(VehicleMonitoringRoutes)} />
      <ModuleMenu currentModuleID={ModuleID.Vehicle} />
    </>
  );
};

const AccessControlHeader = () => {
  const links = useAccessControlMenuLinks();
  return (
    <>
      <MainMenu links={links} routes={Object.values(AccessControlRoutes)} />
      <ModuleMenu currentModuleID={ModuleID.AccessControl} />
    </>
  );
};

const HomeHeader = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: (theme) => theme.palette.background.default,
          px: 1,
          py: 0.5,
          borderRadius: "4px",
          "@media (max-width:350px)": {
            display: "none",
          },
        }}
      >
        <img src={logo} alt="" width="20" height="20" />
        <Typography
          sx={{
            textTransform: "uppercase",
            fontWeight: "bold",
            color: "text.primary",
            "& > span": {
              color: (theme) => theme.palette.primary.main,
            },
            pl: 1,
          }}
          variant="caption"
        >
          <span>Monitoringbook</span> Pro
        </Typography>
      </Box>
      <Box sx={{ flex: 1 }} />
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography>
          <FormattedMessage
            defaultMessage="Choose from the modules <span>available to you</span>"
            values={{
              span: (chunks: string) => (
                <Box
                  component="span"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {chunks}
                </Box>
              ),
            }}
          />
        </Typography>
      </Box>
      <Box flex={1} />
    </>
  );
};

export const AppHeader = () => {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const { stopLoops } = useAudio();

  const isUserSettingsPage = useRouteMatch("/user-settings");

  const { data: me } = useMe();

  return (
    <Header
      position="static"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 3,
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <Switch>
        <Route path="/home">
          <HomeHeader />
        </Route>
        <Route path="/user-settings">
          <UserSettingsHeader />
        </Route>
        <Route path="/alarm">
          <AlarmHeader />
        </Route>
        <Route path="/firesignal">
          <FiresignalHeader />
        </Route>
        <Route path="/smartalarm">
          <SmartAlarmHeader />
        </Route>
        <Route path="/measurements">
          <MeasurementsHeader />
        </Route>
        <Route path="/patrol">
          <PatrolHeader />
        </Route>
        <Route path="/lamp-control">
          <LampHeader />
        </Route>
        <Route path="/gps">
          <VehicleMonitoringHeader />
        </Route>
        <Route path="/acs">
          <AccessControlHeader />
        </Route>
      </Switch>
      <StopAllAlertsButton />
      <VolumeButton />
      {!!me && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "2px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderRadius: "10px",
              border: "1px solid white",
              minHeight: "36px",
            }}
          >
            <Typography
              component={"span"}
              variant="body2"
              color={"contrastText"}
              sx={{ fontWeight: 800 }}
            >
              {!!me.org.name
                ? `${me.username}@${me.org.name}`
                : `${me.username}`}
            </Typography>
          </Box>
        </Box>
      )}
      <SettingsMenu
        settingsItemProps={{
          component: NavLink,
          to: "/user-settings",
          sx: {
            [`.${typographyClasses.root}`]: {
              whiteSpace: "normal",
              maxWidth: "85px",
            },
          },
        }}
        displaySettings={!isUserSettingsPage}
        onClickLogout={() => {
          auth.signout.mutate();
          stopLoops();
        }}
        settingsMessage={formatMessage({
          defaultMessage: "User Settings",
          description: "App header settings button label",
        })}
        logoutMessage={formatMessage({
          defaultMessage: "Logout",
          description: "App header logout button label",
        })}
        userTitle={formatMessage({
          defaultMessage: "Logged In",
        })}
        userName={
          me
            ? me.org.name
              ? `${me.username}@${me.org.name}`
              : me.username
            : ""
        }
        appVersion={process.env.REACT_APP_VERSION}
      />
    </Header>
  );
};

export default AppHeader;

import { SvgIconProps } from "@mui/material";

export interface Module {
  id: ModuleID;
  name?: string;
  path?: string;
  icon?: (props?: SvgIconProps) => React.ReactNode;
  img?: (width?: string, height?: string) => React.ReactElement<"img">;
  active?: boolean;
  hidden?: boolean;
  info?: string;
}

export type ArrayElement<ArrayType extends readonly unknown[] | null> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export enum Lang {
  "hu" = "hu-hu",
  "en" = "en-gb",
  "sk" = "sk-sk",
  "ro" = "ro-ro",
}

export enum ModuleID {
  Dispatcher = "dispatcher",
  Patrol = "patrol",
  Firesignal = "firesignal",
  SmartAlarm = "smartalarm",
  Stock = "stock",
  RemotControl = "remoteControl",
  Vehicle = "vehicle",
  Ozone = "ozone",
  Consumption = "consumption",
  IndoorPositioning = "indoorPositioning",
  Telemedicina = "telemedicina",
  Measurements = "measurements",
  ThermoWatch = "thermowatch",
  Video = "video",
  Lamp = "lamp",
  AccessControl = "accessControl",
}

export enum DispatcherRoutes {
  AlarmOperator = "/alarm/operator",
  Customers = "/alarm/customers",
  Events = "/alarm/events",
  Interventions = "/alarm/interventions",
  Objects = "/alarm/objects",
  Exports = "/alarm/exports",
  Map = "/alarm/map",
  Notifications = "/alarm/notifications",
  SystemSettings = "/alarm/settings",
}

export enum UserSettingsRoutes {
  Profile = "/user-settings/profile",
  Users = "/user-settings/users",
  CompanyInfo = "/user-settings/company-info",
}

export enum FiresignalRoutes {
  Firesignals = "/firesignal",
}

export enum SmartAlarmRoutes {
  SmartAlarms = "/smartalarm",
}

export enum MeasRoutes {
  Dashboards = "/measurements/dashboards",
  Channels = "/measurements/channels",
  Customers = "/measurements/customers",
  Export = "/measurements/export",
  Settings = "/measurements/settings",
}

export enum PatrolRoutes {
  Customers = "/patrol/customers",
}

export enum LampRoutes {
  Lamps = "/lamp-control/lamps",
  Analitics = "/lamp-control/analitics",
  Settings = "/lamp-control/settings",
  Calculator = "/lamp-control/calculator",
  Sync = "/lamp-control/sync",
}
export enum VehicleMonitoringRoutes {
  Waybills = "/gps/waybills",
  PositionData = "/gps/position-data",
  Overview = "/gps/overview",
  SystemSettings = "/gps/settings",
}

export enum AccessControlRoutes {
  RFID = "/acs/rfid",
}

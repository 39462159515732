import { WithFields, WithIncludes } from "@/api";
import { User as _User } from "@/api/admin";
import { JsonapiError, useGetOne } from "@mb-pro-ui/utils";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";

const include = {
  org: true,
  "gps-user": {
    group: true,
  },
  "alarm-operator": true,
} as const;

const fields = {
  users: ["+gps-user", "+alarm-operator", "+urn"],
  "alarm/operators": ["+field-blacklists"],
} as const;

export type User = WithIncludes<
  WithFields<_User, typeof fields>,
  typeof include
>;

export interface MeContextType {
  data?: User;
  isLoading: boolean;
  refetch: () => void;
  error: JsonapiError | null;
}

const MeContext = createContext<MeContextType | undefined>(undefined);

function useMe(): MeContextType;
function useMe(optional?: false): MeContextType;
function useMe(optional: boolean): MeContextType | undefined;

function useMe(optional?: boolean) {
  const value = useContext(MeContext);
  if (!value && !optional) {
    throw new Error("useMe must be used within an MeProvider");
  }
  return value;
}

export const MeProvider = ({ children }: { children: ReactNode }) => {
  const {
    data,
    isLoading,
    refetch: _refetch,
    error,
  } = useGetOne<User>("admin/users", "me", {
    fields,
    include,
    select: (user) => {
      if (user["alarm-operator"]?.deleted && !user["alarm-operator"]?.active) {
        user = { ...user, "alarm-operator": null };
      }
      if (!user["gps-user"]?.status) {
        user = { ...user, "gps-user": null };
      }
      return user;
    },
  });

  const refetchRef = useRef(_refetch);
  useEffect(() => {
    refetchRef.current = _refetch;
  }, [_refetch]);

  const refetch = useCallback(() => {
    refetchRef.current();
  }, []);

  return (
    <MeContext.Provider
      value={useMemo(
        () => ({ data, isLoading, error, refetch }),
        [data, isLoading, error, refetch],
      )}
    >
      {children}
    </MeContext.Provider>
  );
};

export default useMe;

import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as ManualEventIcon } from "../../../../icons/ManualEvent.svg";
import ManualEventModal from "./ManualEventModal";

export const ManualEventButton = ({ account }: { account?: string }) => {
  const [eventModalOpen, setEventModalOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const handleEventModalOpen = () => {
    setEventModalOpen(true);
  };

  const handleEventModalClose = () => {
    setEventModalOpen(false);
  };

  return (
    <>
      <Tooltip
        title={formatMessage({
          defaultMessage: "Send event...",
          description: "Kézi eseményküldés tooltip",
        })}
      >
        <IconButton
          onClick={handleEventModalOpen}
          size="small"
          sx={{ color: "common.white", px: 1 }}
        >
          <SvgIcon component={ManualEventIcon} color="inherit" />
        </IconButton>
      </Tooltip>
      <ManualEventModal
        open={eventModalOpen}
        onClose={handleEventModalClose}
        account={account}
      />
    </>
  );
};

export default ManualEventButton;

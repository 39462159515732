import {
  FormHeader,
  FormHeaderProps,
  TextField as MBPTextField,
  TextFieldProps as MBPTextFieldProps,
} from "@mb-pro-ui/components";
import {
  NumericField,
  TextField,
  TextFieldProps,
} from "@mb-pro-ui/components/form";
import { styled } from "@mui/material";

export const StyledFormheader = styled((props: FormHeaderProps) => (
  <FormHeader {...props} />
))(({ theme }) => ({
  marginTop: "85px",
  backgroundColor: theme.palette.primary.main,
  marginBottom: "52px",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: `calc(100% - ${theme.spacing(2)})`,
}));

export const SimpleFieldArrayTextField = styled(
  ({ sx, deleted, ...props }: TextFieldProps & { deleted?: boolean }) => (
    <TextField
      size="small"
      sx={[
        {
          minWidth: "unset",
          m: 0,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
        !!deleted && { textDecoration: "line-through" },
      ]}
      {...props}
    />
  ),
)({});

export const SimpleFieldArrayNumericField = styled(
  ({ sx, ...props }: TextFieldProps) => (
    <NumericField
      hiddenLabel
      size="small"
      sx={[
        { minWidth: "unset", m: 0, "& .MuiFilledInput-input": { p: 1 } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  ),
)({});

export const StyledNumericField = styled((props: TextFieldProps) => (
  <NumericField {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: `calc(100% - ${theme.spacing(2)})`,
}));

export const StyledMBPTextField = styled((props: MBPTextFieldProps) => (
  <MBPTextField {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  width: `calc(100% - ${theme.spacing(2)})`,
}));

import EmailIcon from "@mui/icons-material/Email";
import { useState } from "react";
import { useIntl } from "react-intl";
import { SnackbarState } from "../settings/types";
import { Snackbar } from "../settings/utils";
import {
  StyledIconButton,
  StyledTooltip,
} from "../utils/StyledHeaderComponents";
import EmailModal from "./EmailModal";
import { EmailButtonProps } from "./types";

const EmailButton = ({
  customer,
  intervention,
  type,
  initialValues,
  templateContext,
  sx,
  setSubtaskStatus,
}: EmailButtonProps) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState<
    SnackbarState | undefined
  >();

  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  return (
    <>
      <StyledTooltip title={formatMessage({ defaultMessage: "Email" })}>
        <StyledIconButton
          onClick={() => setOpen(true)}
          sx={[...(Array.isArray(sx) ? sx : [sx])]}
        >
          <EmailIcon />
        </StyledIconButton>
      </StyledTooltip>
      <EmailModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        setSnackbarState={setSnackbarState}
        customer={customer}
        intervention={intervention}
        type={type}
        initialValues={initialValues}
        templateContext={templateContext}
        setSubtaskStatus={setSubtaskStatus}
      />
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </>
  );
};

export default EmailButton;
